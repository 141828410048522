.ocp--backdrop {
    position: fixed;
    right: 0;
    height: 100vh;
    width: 100vw;
    left: 0;
    overflow: hidden;
    top: -100%;
    transition: top 0s ease-in-out;
    z-index: 100;

    &.open {
        top: 0;
        transition: top 0s 0s;

        .ocp__backdrop {
            opacity: 0.4;
        }

        .ocp__container {
            transform: translate3d(-100%,0,0);
        }
    }
}

.ocp__backdrop {
    background-color: $dark;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity ease .1s;
    width: 100%;
    will-change: opacity;
    flex-grow: 1;
}

.ocp__container {
    max-width: 95vw;
    display: flex;
    flex-direction: row;
    background: $light;
    border-left: #e9e9e9 1px solid;
    box-shadow: 0px 24px 32px rgba(8, 35, 48, 0.06), 0px 8px 16px rgba(8, 35, 48, 0.1);
    will-change: transform;
    transform: translateZ(0);
    transition: transform 0.3s ease-in, width 0.05s linear;
    left: 100%;
    top: 0;
    z-index: 101;
    backface-visibility: hidden;
    height: 100%;
    padding: 0;
    pointer-events: all;
    position: absolute;
    top: 0;
    touch-action: pan-y;
}

.ocp--no-backdrop {
    .ocp__container {
        left: auto;
        right: 0;
        position: fixed;
    }
}

.ocp__resizer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: $spacer;
    margin-left: -$spacer;
    justify-self: flex-end;
    cursor: col-resize;
    resize: horizontal;

    &:hover {
        border-right: #e9e9e9 1px solid;
    }
}

.ocp__close {
    position: absolute;
    padding: $spacer;
    top: 0;
    left: 0;
    z-index: 3;
    width: $spacer*6;
    height: $spacer*6;
    border-radius: $spacer*3;
    background-color: $light;
    margin-left: 6px;
    margin-top: $spacer/2;

    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }
}

.ocp__content {
    flex: 1;
    width: 100%;
    overflow-x: hidden;

    #file-viewer {
        #header-bar {
            padding-left: $spacer*7;

            #file-name {
                max-width: calc(100% - 50px);
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        #no-renderer {
            margin: 40vh auto 0;
        }

        #no-renderer-download {
            margin: 20px auto 0;
        }
    }

    .carousel-root,
    .carousel,
    .slider-wrapper,
    .slider {
        height: 100% !important;
    }

    .carousel {
        .slide {
            height: 100%;
            width: 100%;
            
            > div {
                height: 100%;
                width: 100%;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            iframe {
                width: 100%;
                margin: 0;
            }
        }

        &.carousel-slider {
            .control-arrow {
                top: auto !important;
                bottom: 20px !important;
                margin-top: 0;
                right: auto !important;
                height: 40px;
                width: 40px;
                background: $blue-grey !important;
                border-radius: 20px;
                transform: rotate(90deg);

                &.control-prev {
                    left: calc(50% - 30px);
                }
        
                &.control-next {
                    left: calc(50% + 30px);
                }

                &.control-disabled {
                    display: inline-block !important;
                    opacity: 0.2 !important;
                }
            }
        }
    }
}

.ocp__container.thread-attachments {
    .carousel-root {
        display: flex;
        flex-direction: row-reverse;

        .carousel.carousel-slider {
            width: 70% !important;

            + .carousel {
                width: 30%;
                border-right: 1px solid $grey-300;
                overflow-y: scroll;

                .thumbs-wrapper {
                    margin-right: 0;
                    margin-top: $spacer*6;

                    .control-arrow {
                        display: none;
                    }

                    ul.thumbs {
                        display: flex;
                        flex-direction: column;
                        transform: none !important;

                        .thumb {
                            max-width: 100%;
                            text-overflow: ellipsis;
                            border: none !important;
                            height: $spacer*3;
                            cursor: pointer;

                            &.selected {
                                font-weight: bold;
                            }

                            &:hover {
                                font-weight: bold;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }

        #file-viewer {
            #header-bar {
                padding-left: $spacer*3;
                padding-right: $spacer*3;
    
                #file-name {
                    max-width: 100%;
                }
            }
        }
    }
}

.ocp .popover:not(.popover--image) {
    width: 100%;

    .read-receipts {
        min-width: 300px;
    }
}

.ocp__chat-container {
    position: relative;
    margin-left: $spacer*3;
    padding-top: $spacer*4;
    padding-bottom: $spacer*5;
    height: 100%;
    overflow-y: scroll;

    .chat__feed {
        margin-right: $spacer*4;

        .msg {
            max-width: 100%;

            &--right {
                max-width: calc(100% - 32px);

                .msg__icons {
                    right: auto;
                    left: 100%;
                }
            }

            &:not(.msg--archived) {
                .msg__body {
                    cursor: pointer;
                }
            }
        }
    }
}