.notes-container {
    max-width: 1000px;
}

.property-level-filters.pos-absolute {
    right: 0;

    + .notes-container .note-tag-filters {
        margin-right: 110px;
    }
}

.note {
    background-color: $light;
    padding: $spacer $spacer*3 $spacer*1.5 $spacer*1.5;
    border-radius: $spacer;
    margin-bottom: $spacer*2;
    position: relative;

    .profile-img__wrapper {
        margin-top: auto;
        margin-right: $spacer*1.5;
    }

    .note__corner-btn {
        position: absolute;
        top: -$spacer;
        right: -$spacer;
        background-color: $grey-250;

        &:hover {
            background-color: $grey-300;
        }
    }

    &__text {
        flex-grow: 1;
    }

    &__body {
        margin-bottom: $spacer*1.5;
    }

    &__footer {
        align-items: baseline;
    }

    &__time {
        margin-left: auto;
    }

    &__comments {
        width: 80%;
        margin-left: auto;
        margin-top: $spacer*5;

        .msg--archived {
            display: none;
        }
    }

    &--new {
        padding-right: 0;
        padding-bottom: 0;
        background: none;
        
        .fr-box.fr-basic {
            .fr-element {
                padding-bottom: $spacer;
                min-height: 40px;
            }
        }
    }

    &--pinned {
        background-color: $light-red;

        .note__corner-btn {
            svg {
                fill: $red;

                line {
                    stroke: $red;
                }

                line + line {
                    stroke: $grey-300;
                }
            }
        }
    }

    &--archived {
        background-color: $grey-200;

        > div {
            opacity: 0.3;
        }
    }
}

.note-preview {
    background-color: $light-red;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    padding-right: $spacer;

    &__wrapper {
        margin-top: $spacer/2;

        &:first-child() {
            margin-top: $spacer*3;
        }
    }

    &__text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .icon-wrapper {
        margin-right: $spacer/2;

        svg {
            fill: $red;
        }
    }
}

.comment-editor {
    margin-left: $spacer*5;
    margin-top: $spacer*1.5;
    position: relative;

    .input-box {
        width: 100%;
        border-radius: 25px;
        border-bottom-right-radius: 0;
    }

    .input-box__input {
        padding: $spacer $spacer*1.5;
        height: 30px;
        min-height: 30px;
        resize: vertical;
        font-family: $font-family;

        &::placeholder {
            text-align: right;
        }
    }

    .btn--naked {
        position: absolute;
        right: 0;
        bottom: $spacer/2;
        font-size: 14px;
        color: $grey-600 !important;
        
        &:hover {
            color: $blue !important;
        }
    }
}