.device {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: $spacer*2;
    background-color: $light;
    overflow: hidden;

    .btn--round {
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    &__connection {
        width: 200px;
        padding: $spacer*2 $spacer*3;
    }

    &__contact {
        .profile-img + .icon-wrapper {
            position: absolute;
            top: 40%;
            left: -10px;
        }
    }

    &__dialler {
        input {
            border: none;
            text-align: center;
            font-size: 18px;
            padding: $spacer/2;
            border-radius: $spacer/2;
    
            &:focus {
                outline: none;
                background-color: $light-blue;
            }
        }

        .btn--solid--green:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &__toggle {
        padding: $spacer;
        display: flex;
    }

    &--closed.device {
        &--no-connection {
            border-radius: 50%;
        }

        &--ready {
            border: 2px solid $green;
        }

        &--connecting {
            border: 2px solid $yellow;
        }
        
        &--offline {
            background-color: $grey-400;

            .device__toggle svg {
                fill: $light;
            }
        }
    }

    &--incoming {
        .device__contact {
            .profile-img {
                animation: pulse 1s infinite linear;
            }
        }
    }

    &--canceled {
        background-color: $grey-100;
    }
}

.keypad {
    display: grid;
    list-style: none;
    padding: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 20px 0;
}

.keypad li {
    display: flex;
    justify-content: center;
}

.call__btns {
    .btn--round {
        margin: $spacer
    }
}

.call__footer {
    margin-left: -$spacer*3;
    margin-right: -$spacer*3;
    margin-bottom: -$spacer*2;

    > * {
        border-top: 1px solid $grey-300;
        padding: $spacer !important;
        background-color: $grey-100;
        width: 100%;
        border: none;
        font-weight: 500;
        border-radius: 0 !important;
        color: $dark !important;
        text-transform: none !important;
        border-top: 1px solid $grey-400;

        &:last-child {
            margin-bottom: -$spacer*2;
        }

        &:hover {
            background-color: $grey-200;
        }
    }
}

.btn--solid {
    svg path {
        fill: currentColor;
    }

    &--grey {
        background-color: $grey-100;
        color: $dark
    }

    &--green {
        background-color: $green;
        color: $light;

        &:hover {
            background-color: $dark-green;
        }
    }

    &--red {
        background-color: $red;
        color: $light;

        &:hover {
            background-color: $dark-red;
        }
    }

    &--yellow {
        background-color: $yellow;
        color: $dark;

        &:hover {
            background-color: $dark-yellow;
        }
    }
}

.lister--calls {
    audio {
        width: 250px;
        height: 38px;
        max-width: 20vw;
        display: block;
    }

    audio::-webkit-media-controls-current-time-display,
    audio::-webkit-media-controls-time-remaining-display {
        display: none;
    }

    audio::-webkit-media-controls-panel {
        background-color: $grey-200;
    }

    @media screen and (-webkit-device-pixel-ratio) {
        .audio__wrapper {
            background-color: $grey-200;
            border-radius: $spacer/2;
            display: flex;
        }
    }
}

audio {
    width: 100%;
}
