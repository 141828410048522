.ctrls {
    width: 100%;
    z-index: 2;
    position: sticky;
    left: 0;
}

.ctrls--lister {
    padding-right: 3vw;
    padding-top: 30px;
    @extend .pl-r;
}

.ctrls__list {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.ctrls__listitem {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-bottom: $spacer;
    flex-wrap: nowrap;
    white-space: nowrap;

    .btn.close,
    .toggle {
        &:hover {
            background-color: $grey-300;

            .icon {
                fill: $light;
            }
        }
    }

    .btn.close {
        padding: 2px;
        padding-right: 3px;
        border: none;
        margin-left: auto;

        &:hover {
            .icon {
                transform-origin: center center;
                transform: scale(1.2);
            }
        }
    }

    .btn:disabled,
    .btn.disabled {
        pointer-events: none !important;
    }

    .toggle {
        border-radius: $spacer/4;
    }
}

.ctrls__listitem--search {
    fieldset {
        height: 30px;
        border-radius: $spacer/2;
        padding-right: $spacer/2;
    }

    &:not(.empty) fieldset,
    fieldset:focus-within {
        border: 1px solid $grey-300;
    }

    input {
        font-size: 12px;
        font-family: $font-family;
        font-weight: normal;
        width: 130px;

        &:focus {
            font-size: 14px;
        }
    }
}

.ctrls__listitem--ctrl {
    margin-right: $spacer/2;
    font-size: 14px;
    font-weight: 500;

    .ctrls-listitem__label {
        margin-left: $spacer/2;
        letter-spacing: 0.44px;
    }
    
    .btn {
        padding: 3px;
        font-family: $font-family-headings;
    }

    .toggle:focus {
        outline: none;
        border-radius: $spacer/2;
    }
}

.ctrls__listitem--option {
    .profile-img__wrapper {
        margin-right: 5px;
    }

    .btn {
        font-size: 12px;
        border: 1px solid $grey-300;
        border-radius: $spacer/2;
        padding: 0 $spacer/2;
        font-weight: normal;

        &--profile-icon {
            .label.label--profile {
                padding-left: $spacer*1.5;
            }
            .icon-wrapper:not(.icon-wrapper--close) {
                position: absolute;
                top: 0;
                left: 20px;

                .icon {
                    width: $spacer*1.5;
                    height: $spacer*1.5;
                }
            }
        }
    }

    .label {
        padding: $spacer/4;
        letter-spacing: 0.4px;

        &.label--profile {
            padding-left: $spacer;
        }
    }

    button:not(.w-auto) {
        min-width: 130px;
    }
}

.lister__cell--labels {    
    .img {
        margin: 0!important;
    }
}

.split-panel--main-bottom {
    .ctrls--lister {
        padding-top: 0;
    }
}
