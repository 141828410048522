.t-red { color: $red; }
.t-yellow { color: $yellow; }
.t-green { color: $green; }
.t-lightgrey { color: $grey-400; }
.t-darkgrey { color: $grey-800; }
.t-grey { color: $grey-600; }
.t-dark { color: $dark; }
.t-blue { color: $blue; }
.t-blue-grey { color: $blue-grey; }
.bg-light { background-color: $light; }
.bg-lightblue { background-color: $light-blue; }
.bg-grey { background-color: $grey-200; }
.bg-lightgrey { background-color: $grey-100; }

.t-hover {
    &--blue:hover {
        color: $blue
    }
}

.t-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// positioning
.pos-absolute { position: absolute; }
.pos-fixed    {
    position: fixed !important;
    z-index: 21;
}
.pos-relative { position: relative !important; }
.pos-static   { position: static !important; }
.pos-sticky   {
    @supports (position: sticky) {
        position: sticky !important;
        z-index: 20;
    }
}
.pos-center {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}
.pos-center-x {
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%) !important;
}
.pos-center-y {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%) !important;
}
.pos-left { left: 0; }
.pos-right { right: 0; }
.pos-top { top: 0; }

.float-right { float: right; }
.float-laft { float: left; }

.z-index-1 { z-index: 1; }
.z-index-10 { z-index: 10; }


// spacing
.spacer--4 { height: $spacer*4; }
.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }
.mb-auto { margin-bottom: auto; }
.mx-auto { margin: 0 auto!important; }
.my-auto { margin: auto 0!important; }
.ml-auto { margin-left: auto!important; }
.mr-auto { margin-right: auto!important; }
.m-0 { margin: 0!important;}
.m-1 { margin: $spacer;}
.my-05 { margin-top: $spacer/2; margin-bottom: $spacer/2; }
.mb-0 { margin-bottom: 0!important;}
.mb-05 { margin-bottom: $spacer/2; }
.mb-025 { margin-bottom: $spacer/4; }
.mb-1 { margin-bottom: $spacer; }
.mb-2 { margin-bottom: $spacer*2; }
.mb-3 { margin-bottom: $spacer*2.5; }
.mb-4 { margin-bottom: $spacer*3; }
.mb-5 { margin-bottom: $spacer*3.5; }
.mb-6 { margin-bottom: $spacer*4; }
.mt-05 { margin-top: $spacer/2; }
.mt-1 { margin-top: $spacer; }
.mt-2 { margin-top: $spacer*2; }
.mt-3 { margin-top: $spacer*2.5; }
.mt-4 { margin-top: $spacer*3; }
.mt-5 { margin-top: $spacer*3.5; }
.mt-6 { margin-top: $spacer*4; }
.mr-0 { margin-right: 0!important; }
.mr-05 { margin-right: $spacer/2!important; }
.mr-1 { margin-right: $spacer; }
.mr-15 { margin-right: $spacer*1.5; }
.mr-2 { margin-right: $spacer*2; }
.mr-3 { margin-right: $spacer*2.5; }
.mr-4 { margin-right: $spacer*3; }
.mr-5 { margin-right: $spacer*3.5; }
.mr-6 { margin-right: $spacer*4; }
.ml-0 { margin-left: 0!important; }
.ml-05 { margin-left: $spacer/2; }
.ml-1 { margin-left: $spacer; }
.ml-2 { margin-left: $spacer*2; }
.ml-3 { margin-left: $spacer*2.5; }
.ml-4 { margin-left: $spacer*3; }
.ml-5 { margin-left: $spacer*3.5; }
.ml-6 { margin-left: $spacer*4; }
.ml-r {
    margin-left: 50px!important;
    @media screen and (min-width: $screen-xl) {
        margin-left: 70px!important;
    }
}
.p-0 { padding: 0!important; }
.p-05 { padding: $spacer/2!important; }
.p-1 { padding: $spacer; }
.p-2 { padding: $spacer*2; }
.p-3 { padding: $spacer*3; }
.pr-1 { padding-right: $spacer!important; }
.pl-r {
    padding-left: 50px!important;
    @media screen and (min-width: $screen-xl) {
        padding-left: 70px!important;
    }
}
.pb-0 { padding-bottom: 0!important;}
.pb-05 { padding-bottom: $spacer/2; }
.pb-1 { padding-bottom: $spacer; }
.pb-2 { padding-bottom: $spacer*2; }
.pb-4 { padding-bottom: $spacer*4; }
.pt-1 { padding-top: $spacer; }
.pt-2 { padding-top: $spacer * 2; }
.pt-3 { padding-top: $spacer * 3; }
.pt-4 { padding-top: $spacer * 4; }


@media screen and (min-width: $screen-sm) {
    .mb-3 { margin-bottom: $spacer*3; }
    .mb-4 { margin-bottom: $spacer*4; }
    .mb-5 { margin-bottom: $spacer*5; }
    .mb-6 { margin-bottom: $spacer*6; }
}

.mx--1 {
    margin-left: -$spacer;
    margin-right: -$spacer;
}
.mx--05 {
    margin-left: -$spacer/2;
    margin-right: -$spacer/2;
}


//display
.d-block { display: block; }
.d-inline-block { display: inline-block; }
.d-flex { display: flex; }
.d-column { display: flex; flex-direction: column; align-items: center;}
.d-inline-flex { display: inline-flex; align-items: center; }
.flex-grow-1 { flex-grow: 1; }
.flex-wrap { flex-wrap: wrap; }
.flex-reverse { flex-direction: row-reverse; }
.justify-space-between { justify-content: space-between !important; }
.justify-center { justify-content: center; }
.justify-end { justify-content: end; }
.justify-start { justify-content: start !important; }
.align-center { align-items: center; }
.align-self-center { align-self: center; }

* {
    box-sizing: border-box;
    margin: 0;
}

// typography
$font-family: 'Roboto', sans-serif;
$font-family-headings: 'Quicksand', sans-serif;

body {
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $grey-100;
    color: $dark;
}

a { color: inherit; }
aside { display: inline-block }
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.t-xs { @include t-xs(); }
.t-s { @include t-s(); }
.t-sm { @include t-sm(); }
.t-s { @include t-s(); }
.t-md { @include t-md(); }
.t-lg { @include t-lg(); }
.t-xl { @include t-xl(); }
.t-2xl { @include t-2xl(); }

.t-sm--responsive {
    @include t-sm();

    @media screen and (min-width: $screen-lg) {
        @include t-md();
    }
}

.t-lg--responsive { 
    @include t-lg();

    @media screen and (min-width: $screen-lg) {
        @include t-xl();
    }
}

.t-heading { 
    font-family: $font-family-headings;
    white-space: pre-line;
    font-weight: 500;
}

.t-bold {
    font-weight: bold;
}

.t-normal {
    font-weight: normal;
}

.t-500 {
    font-weight: 500;
}

.t-uppercase {
    text-transform: uppercase;
}

.t-none,
.t-none a {
    text-decoration: none !important;
    text-transform: none !important;
}

.t-underline {
    text-decoration: underline;
}

.t-underline-hover {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.t-left { text-align: left; }
.t-right { text-align: right; }
.t-center { text-align: center; }

.lh-20 {
    line-height: 20px;
}

.no-wrap {
    white-space: nowrap;
}

.ws-pre-line {
    white-space: pre-line;
}

.b-none {
    border: none!important;
}

.bb {
    border-bottom: 1px solid $grey-300;
}

.w-auto {
    width: auto;
}

.w-100 {
    width: 100%;
}

.w-80 {
    width: 80%;
}

.h-100 {
    height: 100%;
}

.site-container {
    display: flex;
}

.page-container {
    width: calc(100vw - 80px);
    height: 100vh;
    position: relative;
}

mark {
    color: inherit;
    background-color: $yellow;
    border-radius: 2px;
}

// border
.br-05 { border-radius: $spacer/2; }
.br-1 { border-radius: $spacer; }

// cursor
.cursor {
    &--pointer {
        cursor: pointer;
    }
}

.hover--show {
    display: none;
}

button:hover {
    .hover--show {
        display: initial;
    }

    .hover--hide {
        display: none;
    }
}
