.profile-img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--2xs {
        width: $spacer*2;
        height: $spacer*2;
    }

    &--xs {
        width: 24px;
        height: 24px;
    }

    &--md {
        width: 36px;
        height: 36px;
    }

    &--lg {
        width: 40px;
        height: 40px;
    }

    &--xl {
        width: 50px;
        height: 50px;
    }

    &--2xl {
        width: 75px;
        height: 75px;
    }

    &--3xl {
        width: 96px;
        height: 96px;
    }

    &--noborder {
        border: 0;
    }

    &--employees, &--4_employees {
        box-shadow: 0px 0px 0px 2px $blue-shaded
    }

    &--guests, &--1_guests {
        box-shadow: 0px 0px 0px 2px $yellow;
    }

    &--partners, &--3_partners {
        box-shadow: 0px 0px 0px 2px $violet;
    }

    &--landlords, &--2_landlords {
        box-shadow: 0px 0px 0px 2px $green;
    }
}

.profile-img__wrapper {
    .icon-wrapper--dot {
        position: absolute;
        bottom: 0px;
        right: -5px;

        &::after {
            border: 2px solid $light;
            box-shadow: 0px 2px 4px 0px $grey-300;
        }
    }
}

.profile-img--icon {
    .icon-wrapper {
        width: 100%;
        height: 100%;
    }

    .icon {
        margin: 0;
        width: 100%;
        height: 100%;
    }
}

.profile-img__icon-tag {
    position: absolute;
    top: 0px;
    right: -5px;
}