@mixin loader_animating() {
    animation-delay: -0.16s;

    &::before,
    &::after,
    & {
        animation: loading 1.8s infinite ease-in-out;
        animation-fill-mode: both;
    }

    &::before {
        animation-delay: -0.32s;
    }

    &::after {
        animation-delay: 0.32s;
    }
    }

    @keyframes loading {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em $grey-300;
    }

    40% {
        box-shadow: 0 2.5em 0 0 $grey-300;
    }
}

.loader {
    display: block;
    font-size: 5px;
    margin: 0 auto;
    position: relative;

    &::before,
    &::after,
    & {
        border-radius: 100%;
        width: 2.5em;
        height: 2.5em;
    }

    &::before {
        left: -3.5em;
    }

    &::after {
        left: 3.5em;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
    }

    @include loader_animating();

    &.pos-center-y {
        transform: translateY(calc(-50% - 30px)) !important;
    }
}

.loader--inline {
    display: inline-block;
    margin: 0 $spacer*2 0 $spacer*3;
    top: -$spacer*1.5;
}
