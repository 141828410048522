.counter {
    background-color: $blue;
    display: inline-block;
    color: $light;
    margin-left: $spacer;
    font-size: $spacer*1.5;
    font-family: $font-family;
    min-width: $spacer*2;
    height: $spacer*2;
    padding: 0 $spacer/2;
    border-radius: $spacer;
    line-height: $spacer*2;
    text-align: center;

    &:not(.counter--inline) {
        position: absolute;
        top: -5px;
        right: -5px;
    }
}