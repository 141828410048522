.label {
    display: inline-flex;
    align-items: center;
    padding: 6px;
    border-radius: $spacer/2;
    color: $dark;
    white-space: nowrap;

    .icon {
        margin-right: 5px;
    }

    &--red {
        background-color: $light-red;

        .icon {
            fill: $red;
        }
    }

    &--yellow {
        background-color: $light-yellow;

        .icon {
            fill: $yellow;
        }
    }

    &--green {
        background-color: $light-green;

        .icon {
            fill: $green;
        }
    }

    &--grey {
        background-color: $grey-200;

        .icon {
            fill: $grey-600;
        }
    }

    &--blue {
        background-color: $light-blue;

        .icon {
            fill: $blue;
        }
    }

    &--bordered {
        border: 1px solid $grey-600;
        padding: 5px;
    }

    &.time-label,
    &--uppercase {
        font-size: 9px;
        line-height: 10px;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 1px;
        padding-top: 7px;
        padding-bottom: 5px;

        .icon {
            top: -1px;
            position: relative;
        }
    }
}
