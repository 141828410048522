.lister--threads {
    flex-grow: 1;
    overflow: auto;

    .batch-actions .pop-up__btns {
        .btn {
            min-width: 107px;
        }
    }
}

.thread__list {
    overflow: auto;
    min-height: 100%;
}

.thread__listitem {
    border-radius: $spacer;
    background-color: $grey-100;
    margin: $spacer $spacer*2;

    &:first-child {
        margin-top: $spacer*2;

        .fr-popup,
        .fr-dropdown-menu {
            top: auto !important;
            left: 50% !important;
            bottom: -10px;
            transform: translate(-50%, 100%);
        }
    }

    .thread__chat {
        overflow: visible;
    }

    .new-mgs__container {
        background: none;
    }

    &.closed {
        .thread__header {
            transition: border-bottom-color 0.05s ease 0.3s;
            border-bottom-color: transparent;
        }

        .thread__chat {
            transition: max-height 0.3s ease-in-out, opacity 0.1s ease-out 0.2s;
            max-height: 0;
            opacity: 0;
        }
    }

    a {
        text-decoration: none;
    }

    .chat__body {
        padding: $spacer*2 $spacer*2.5;
    }
}

.thread__header {
    display: flex;
    align-items: center;
    height: 45px;
    padding-left: $spacer*1.5;
    padding-right: $spacer;
    border-bottom: 1px solid $grey-250;

    button {
        &:first-child {
            border-radius: 50%;
            padding: 2px;

            &:hover {
                background-color: $light-blue;
            }
        }

        &:disabled {
            pointer-events: none;
        }

        &:last-child:hover {
            background-color: transparent;

            .icon {
                transform: scale(1.1);

                &.caret--right {
                    transform: rotate(-90deg) scale(1.1);
                }
            }
        }
    }
}

.thread__title {
    margin-right: auto;
    margin-left: $spacer;
}

.thread__chat {
    // max-height: 1000px;
    opacity: 1;
    overflow: auto;
    transition: max-height 0.3s ease-in-out, opacity ease-in 0.1s;
    transform-origin: top;
}