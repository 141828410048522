.checkbox__container {
    width: 100%;
    display: flex;

    &.checked .checkbox__label {
        text-decoration: line-through;
    }
}

.checkbox__input {
    cursor: pointer;

    &:hover {
        :not(input:disabled) + .checkbox--styled .icon {
            background-color: $light-blue;
            border-radius: $spacer/2;
        }
    }

    input:disabled + .checkbox--styled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.checkbox__label {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.checkbox__text {
    display: inline-block;
    max-width: 179px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.checkbox__text {
    padding: 6px;
    margin-left: 3px;
    border-radius: $spacer/2;

    &:hover {
        background-color: $light-blue;
    }
}

.checkbox--hidden {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox--styled {
    display: inline-flex;
    margin-right: 18px;
    align-items: center;
    height: 100%;
}
