
.lister {
    padding-bottom: 40px;

    .react-dropdown-select {
        z-index: 1;
    }
}

.lister__table {
    width: calc(100% - 3vw);
    border-collapse: collapse;
}

thead .spacer-row {
    th {
        background-color: $light;
        position: sticky;
        top: 0;
        z-index: 1;
    }
}

.lister-table__wrapper {
    display: flex;
}

.lister__header {
    color: $light;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 30px;
    letter-spacing: 1.5px;
    border-radius: $spacer/2;

    + .spacer-row {
        height: $spacer/2;
    }
}

.lister-header__cell {
    white-space: nowrap;
    vertical-align: middle;
    background-color: #93A6B0;
    padding: 0;
    position: sticky;
    top: 7px;
    z-index: 1;

    .btn {
        width: 100%;
        text-transform: uppercase !important;
        line-height: inherit;
        font-size: inherit;
        font-weight: inherit;
        padding: 0 21px 0 $spacer*2;

        &:disabled {
            pointer-events: none;
        }
    }

    &:last-child {
        border-radius: 0 $spacer/2 $spacer/2 0;
    }

    &:first-child {
        border-radius: $spacer/2 0 0 $spacer/2;
    
        .icon-wrapper--caret {
            position: relative;
            top: 5px;
        }

        .btn {
            padding: 0;
        }
    }

    &:not(:first-child) .icon-wrapper--caret,
    .icon-wrapper--caret.pos-absolute {
        position: absolute;
        top: 7px;
        left: 100%;
    }

    .icon-wrapper--dots-vertical {
        position: absolute;
        top: 5px;
        right: -8px;
    }

    .icon--dots-vertical {
        fill: $light;
        opacity: 0.3;
    }

    .icon--caret {
        fill: $light;
        opacity: 0;
        transition: transform 0.2s ease-in-out;
        transform-origin: center center;

        &.sort--1 {
            transform: rotateX(180deg);
        }
    }

    &:hover {
        .icon--caret {
            opacity: 0.5;
        }
    }

    &.active {
        .icon--caret {
            opacity: 1;
        }
    }
}

.lister-group__header {
    border-top: none !important;
    font-family: $font-family-headings;

    .lister-group-header__cell {
        white-space: nowrap;
        height: 40px;
        overflow: visible;
        max-width: 36px;
        padding-left: $spacer;

        .lister-cell__wrapper {
            justify-content: flex-start;
        }
    }

    th {
        background-color: $grey-200;
    }

    + .lister__row {
        border-top: none !important;
    }
}

.lister__row {
    vertical-align: middle;

    &:not(:first-child) {
        border-top: 1px solid $grey-250;
    }
    
    &:hover {
        .lister__cell {
            background-color: rgba(0,0,0,0.01);
        }
    }
}

.lister__cell {
    cursor: pointer;
    white-space: nowrap;
    height: 44px;
    padding: 0 $spacer*1.5 0 $spacer/2;
    margin: auto 0;
    line-height: 20px;
    max-width: 20vw;
    overflow-x: hidden;

    &.lister__cell--multi {
        max-width: 250px;
        overflow-x: auto;
    }

    &.lister__cell--200 {
        max-width: 200px;
    }

    &:nth-child(2) {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        + .lister__cell:not(.t-left) {
            text-align: right;
        }
    }

    &:first-child {
        padding-left: $spacer;
    }

    &:last-child {
        padding-right: $spacer/2;
    }

    .label {
        font-size: 14px;

        &.time-label {
            font-size: 9px;
        }
    }

    .icon--ticket {
        transform: rotateY(180deg);
        fill: $blue-grey;
    }

    &:not(.lister__cell--multi) {
        .label {
            width: 100%;
            justify-content: center;

            span:not(.icon-wrapper) {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.lister-cell__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    vertical-align: middle;

    .icon {
        flex-shrink: 0;
    }
}

.spacer-row {
    height: $spacer;
}

tbody .spacer-row:first-child() {
    display: none;
}

table.spacer-after {
    width: 3vw;
    min-width: 3vw;
}

.lister--templates,
.lister--properties {
    .lister__cell {
        &:nth-child(2) {
            width: auto;
            text-overflow: unset;

            + .lister__cell {
                text-align: center;
            }
        }

        &:nth-child(1) {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.lister--attachments {
    .lister__cell {
        cursor: auto;
        width: auto;
        text-overflow: unset;

        &--wide {
            width: 100%;
            min-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .lister__row:hover .lister__cell {
        background: none;
    }
}

.lister--single-cell {
    .lister__cell {
        max-width: 100%;
        width: 100%;
        padding: 0;
        height: auto;

        fieldset > div.textfield__input {
            grid-column: 1 / 3;
            padding-top: $spacer;
        }
    }
}

.lister--attachments.lister--single-cell {
    .react-dropdown-select {
        bottom: 30px;
        right: 0;
    }
}

.building-detail,
.property-detail,
.unit-detail {
    .ctrls__list {
        display: flex;
    }
}
