// breakpoints
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 960px;
$screen-lg: 1200px;
$screen-xl: 1440px;
$screen-xxl: 1600px;
$screen-xxxl: 1920px;


// colors
$dark: #31383C;
$light: #ffffff;
$grey-100: #F7F9FA;
$grey-200: #F1F3F4;
$grey-250: #E3E5E5;
$grey-300: #C6CACC;
$grey-400: #C0C0C0;
$grey-600: #919699;
$grey-800: #5E6366;
$blue-grey: #78909C;
$blue-shaded: #8AB9F6;
$blue: #367BF5;
$light-blue: #E6EFFF;
$red: #EA3D2F;
$light-red: #FEE4E2;
$dark-red: #b62a20;
$yellow: #F3AA18;
$dark-yellow: #d3910c;
$light-yellow: #F1E7D1;
$green: #3db65d;
$light-green: #DCF7E3;
$dark-green: #3c804d;
$light-violet: #FCEAFE;
$violet: #745577;


$spacer: 8px;

// typography
$font-family: 'Roboto', sans-serif;
$font-family-headings: 'Quicksand', sans-serif;

// fixed sizes
$tab-nav-height: 36px;
$header-height: 98px;
$tab-panel-height: calc(100vh - 98px);
$vertical-pannel-width: 400px;
$vertical-pannel-width-small: 300px;
$site-nav-width: 200px;
$site-nav-collapsed-width: 80px;
