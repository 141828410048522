.mentions__list {
    background-color: $grey-100;
    height: 100%;
    overflow-y: scroll;
}

.mentions__listitem {
    background-color: $light;
    border: 1px solid $grey-250;
    border-radius: $spacer;
    margin: $spacer $spacer*2;

    &:first-child() {
        margin-top: $spacer*2;
    }

    > a {
        text-decoration: none;
        padding: $spacer*1.5;
        display: block;
    }

    .counter {
        margin-left: 0;
        margin-right: $spacer;
    }
}

a.fr-deletable.mention {
    text-decoration: none;
    background-color: $light-blue;
    color: $blue;
    padding: 1px 3px;
    border-radius: 9px;
    white-space: nowrap;
}
