.select-contact {
    position: relative;
    width: 100%;
    max-width: 90vw;
    margin-bottom: $spacer*2;
    transition: padding-bottom 0.2s ease;

    &__input-label {
        position: absolute;
        top: $spacer/2;
        left: $spacer*1.5;
        font-size: 14px;
    }

    &--focused {
        &__input-label {
            color: $blue;
        }
    }

    .react-dropdown-select-dropdown-handle {
        display: none;
    }

    .react-dropdown-select {
        border-color: $grey-300;
        border-radius: $spacer/2;
        padding: $spacer*3 $spacer*1.5 2px;

        &:focus,
        &:hover,
        &[aria-expanded="true"] {
            box-shadow: none !important;
            border-color: $grey-300;
        }
    }

    .react-dropdown-select-input {
        &:first-child {
            margin: 0;
        }
    }

    .react-dropdown-select-option {
        position: relative;
        background: none;
        padding: 0;
        color: $dark;

        &:first-child {
            margin-left: 0;
        }
    }

    .react-dropdown-select-option-remove {
        position: absolute;
        right: 0;
        margin-right: 0 !important;
        color: $grey-400;

        &:hover {
            color: $dark !important;
            transform: scale(1.2);
            transition: all 0.2s ease-in;
        }
    }

    .react-dropdown-select-content {
        .icon-wrapper {
            margin-right: $spacer;

            &--close {
                margin-right: 0;
            }
        }
    }

    .react-dropdown-select-dropdown {
        width: 100%;
        max-width: 500px;
        max-height: 260px;
        border: none;
        box-shadow: inset 0px -4px 5px -6px rgba(0, 0, 0, 0.5);
        background-color: $grey-200;
        border-radius: $spacer/2;
    }

    .react-dropdown-select-item {
        border: none;
        padding: 3px $spacer*1.5;
        line-height: 20px;
        color: $grey-800;
        min-height: 26px;
        width: 100%;
        overflow: hidden;

        span:last-child {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &:first-child {
            padding-top: $spacer;
        }

        &:hover,
        &:focus {
            background-color: $light-blue;
        }

        .icon-wrapper {
            margin-right: $spacer;
        }
    }
}

.new-message-form {
    .msg-editor {
        padding: 0;

        .send-btn {
            right: 2px;
        }
    }
}
