.context-menu {
    position: fixed;
    width: max-content !important;

    .pop-up__container {
        padding: 0;
    }

    .context-menu__item {
        padding: $spacer $spacer*3 $spacer $spacer*1.5;
        cursor: pointer;
        @include t-sm();
        display: flex;
        align-items: center;

        .icon-wrapper {
            margin-right: $spacer*2;
        }

        &:hover {
            background-color: $light-blue;
        }

        &:first-child {
            padding-top: $spacer*1.5;
        }

        &:last-child {
            padding-bottom: $spacer*1.5;
        }
    }

    .context-menu__header {
        background-color: $grey-100;
    }
}