.aside-panel {
    position: sticky;
    top: 0;
    float: left;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $light;
    box-shadow: 0px 24px 32px rgba(8, 35, 48, 0.06), 0px 8px 16px rgba(8, 35, 48, 0.1);
    font-family: $font-family-headings;
    width: $site-nav-width;
    flex-shrink: 0;
    transition: width 0.2s ease-in-out;
    padding: $spacer*2;
    padding-bottom: $spacer*3;
    z-index: 10;
    cursor: pointer;

    &.collapsed {
        width: $site-nav-collapsed-width;

        .nav-listitem__label {
            opacity: 0;
        }
    }

    &:hover {
        button.aside-panel__toggle {
            opacity: 1;
        }
    }

    &__logo {
        width: 48px;
        height: auto;
    }

    &__bottom {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $blue-grey;

        .btn--logout:hover {
            color: $dark !important;
        }
    }

    &__toggle {
        opacity: 0;
        position: absolute;
        top: 50%;
        padding: 10px;
        right: -25px;
        transform: translateY(-50%);
        transition: opacity 0.3s ease-in;
        color: $blue-grey !important;
    
        &:hover {
            color: $dark !important;
        }
    }
}
