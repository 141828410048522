.ticket-action--merge {
    .select-form--search {
        top: calc(100% + 10px);
        left: -175px;
        width: 440px !important;
        transform-origin: bottom center;
    }
}

.ticket-board {
    padding: 30px 3vw 0 70px;
    display: flex;
    flex-direction: column;
    @extend .pl-r;

    .tab__panel--main__header {
        .textfield-form--inline {
            padding-right: 0;
        }

        .btn--toggle {
            padding: $spacer;
            margin-left: $spacer;
            top: 5px;

            &:disabled {
                pointer-events: none;

                .icon {
                    transform-origin: center;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.ticket {
    .split-panel--main-bottom {
        .notes-container,
        .lister--threads {
            padding-bottom: 200px;
        }
    }
}
