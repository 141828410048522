.board {
    display: flex;
    flex-grow: 1;
    max-height: $tab-panel-height;
}

.board-spacer {
    width: 5px;
    flex-grow: 0;
}

.board-column {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 0;
    min-width: 320px;
    max-width: 430px;
    padding-bottom: $spacer*2;

    &:first-child {
        margin-left: -1vw;
    }
}

.board-column__header {
    padding: $spacer 1vw;
    display: flex;
    justify-content: space-between;

    .board-column-title {
        flex-grow: 1;
        display: flex;
        align-items: center;

        .icon-wrapper--status {
            margin-right: $spacer;
            order: 0;
        }

        &--grey {
            color: $blue-grey;
        }

        &--yellow {
            color: $yellow;
        }

        &--green {
            color: $green;
        }
    }

    button {
        order: 2;
        border-radius: 50%;
    }
}

.board-column__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 6px 1vw $spacer*2;
    border-radius: $spacer/2;
    max-height: calc(100% - 40px);
    overflow-y: auto;

    &.hovered {
        background-color: $light-blue;
        border: 1px dashed $blue;
        border-radius: $spacer/2;
    }

    .btn--new-item {
        display: block;
    }
}

.board-card {
    border-radius: $spacer/2;
    border: 1px solid $grey-250;
    padding: $spacer*2;
    background-color: $grey-200;
    margin-bottom: $spacer*2;

    &.hovered {
        box-shadow: 0px 15px 10px rgba(8, 35, 48, 0.08), 0px 6px 5px rgba(8, 35, 48, 0.14);
    }

    footer {
        display: flex;

        .time-label {
            margin-left: auto;
        }
    }

    a:first-child {
        display: block;
        text-decoration: none;
        overflow: hidden;
    }

    .label--grey {
        background-color: $grey-250;
    }
}

.board-card-label {
    margin-bottom: $spacer;

    &:not(:last-child) {
        margin-right: $spacer;
    }
}

@keyframes scale-in-y {
    0% { transform: scaleY(0); }
    100% { transform: scaleY(1); }
}

.draggable {
    transform-origin: top center;
    // animation: scale-in-y 0.15s ease-in;
    max-height: 500px;
    transition: max-height 0.15s ease-out;
    margin: -1px;

    &.dragged {
        max-height: 0px;
        opacity: 0;
    }
}

.board-column__ctrls {
    width: 5px;
    padding: $spacer 0;
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;

    .icon--dots-vertical {
        height: $spacer*3;
    }

    .dots-wrapper {
        flex-shrink: 0;
        position: relative;
        left: calc(-2vw - 7.5px);
        width: calc(2vw + 10px);
        text-align: center;
    }

    .board-column__placeholder {
        height: 100%;
        margin: $spacer*3 auto $spacer*2;
        width: 0px;
    }

    button {
        transform: scaleX(0);
        transition: transform 0.1s ease-in-out;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        z-index: 1;

        &:first-child {
            transform-origin: right;
            right: calc(2vw + 2.5px);
        }

        &.btn--icon-plus {
            transform-origin: left;
            right: -7.5px;

            &:hover +div {
                border-left: 1px dashed $blue;
            }
        }

        &:hover {
            background-color: $light-blue;
            .icon {
                fill: $blue;
            }
        }
    }

    &:hover {
        button {
            transform: scaleX(1);
        }
    }
}

.textfield-form--board-name {
    input {
        width: 300px;
    }

    .icon-wrapper--pencil {
        right: auto;
        left: -35px;
        top: 15px;
    }
}

.textfield-form--board-column-name {
    padding: 0;
    width: 100%;
    order: 1;

    input {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .icon-wrapper--pencil {
        bottom: -15px;
        right: auto;
        top: 50%;
        transform: translateY(-50%);
        left: -33px;
    }

    &:hover {
        +.icon-wrapper {
            transform: scale(0)
        }
    }
}
