.timeline,
.timeline-item--left {
    display: flex;
    flex-direction: column;
}

.timeline-item--left {
    align-items: center;
}

.timeline-item--right {
    align-items: center;
    padding: $spacer 0 $spacer*3 $spacer*1.5;
    flex-grow: 1;
    max-width: 93%;
}

.timeline__icon {
    display:flex;
    padding: 5px;
    border-radius: 50%;
    background-color: $grey-200;
}

.timeline__line {
    flex-grow: 1;
    width: 1px;
    background-color: $grey-400;
    margin: $spacer/2;
}

.timeline__time {
    color: $grey-600;
    @include t-sm();
    white-space: nowrap;
    padding-left: $spacer;
}

.timeline__item {
    display: flex;

    &:last-child() {
        .timeline__line {
            display: none;
        }
    }
}

.timeline-item__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer;
}

.timeline-item__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.timeline-item__content {
    @include t-sm();
}

// variants
.timeline--350 {
    max-width: 350px;
}

.timeline--650 {
    max-width: 650px;
}