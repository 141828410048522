fieldset {
    margin: 0;
    padding: 0;
    border: none;
    max-inline-size: 100%;

    > fieldset {
        min-inline-size: auto;
    }

    &:disabled {
        pointer-events: none;
    }

    fieldset {
        position: relative;
        margin-bottom: $spacer * 2;

        &:focus-within {
            label {
                color: $blue;
            }
        }

        label:not(.upload-file),
        .upload-file__label {
            @include t-xs();
            position: absolute;
            top: 0;
            left: 0;
            padding: $spacer $spacer*1.5 0;
            color: $grey-800;
            z-index: 1;
        }

        textarea {
            resize: vertical;
        }
        
        .fieldset__search-select {
            box-shadow: none !important;

            &:hover {
                border-bottom: 1px solid $grey-300;
            }

            input {
                margin-left: 0;
            }

            .react-dropdown-select-content {
                font-size: inherit !important;
                color: inherit;
                max-width: calc(100% - 20px);
            
                > span {
                    margin: 0;
                    padding: 0;
                    line-height: $spacer*3;
                    max-width: calc(100% - 14px);
                }

                &:not(.react-dropdown-select-type-single) {
                    > span:not(:last-child)::after {
                        content: ', ';
                        white-space: pre;
                    }
                }

                .react-dropdown-select-option {
                    background: none;
                    color: inherit;
                }
            }

            .react-dropdown-select-option-label {
                white-space: nowrap;
            }

            .react-dropdown-select-option-remove {
                display: none;
            }

            .react-dropdown-select-input {
                color: inherit;
                opacity: 1;

                &:not(:first-child) {
                    margin-left: $spacer;
                }
            }

            &.edited {
                color: $blue
            }

            // &:focus-within {
            //     .react-dropdown-select-content > span {
            //         display: none;
            //     }
            // }
        }

        .upload-file__input {
            height: 54px;
        }

        label + input, textarea, select, .fieldset__search-select, .upload-file__input {
            padding: $spacer*3 $spacer*1.5 $spacer/2;
            color: $grey-800;
            background-color: $grey-100;
            border-radius: $spacer/2 $spacer/2 0px 0px;
            border: none;
            border-bottom: 1px solid $grey-300;
            font-size: 16px;
            line-height: 24px;
            font-family: $font-family;
            width: 100%;
            transition: background-color 0.15s ease-in, border-bottom-color 0.15s ease-in;

            &:focus, &:active, &:focus-within {
                background-color: $light-blue;
                outline: none;
                border-bottom: 2px solid $blue;
                color: $blue;
            }

            &.invalid {
                border-bottom: 1px solid $red;
            }

            &:-webkit-autofill {
                -webkit-text-fill-color: $grey-800;
                box-shadow: 0 0 0px 1000px $grey-100 inset;
                color: $grey-800;

                &:focus, &:active, &:focus-within {
                    box-shadow: 0 0 0px 1000px $light-blue inset;
                    -webkit-text-fill-color: $blue;
                    color: $blue;
                }

                &.t-blue {
                    -webkit-text-fill-color: $blue;
                }
            }
        }

        ::placeholder {
            color: $grey-300;
        }

        input[type='checkbox'] {
            margin-top: $spacer*4;
            margin-left: $spacer*1.5;
            margin-right: auto;
            width: auto;
            display: block;
        }

        input[type='file']::-webkit-file-upload-button {
            display: none;
        }

        &:not(.success) {
            input, textarea, select {
                &:disabled {
                    opacity: 0.5;
                }
            }

            .fieldset__search-select[disabled] {
                opacity: 0.5;
            }

            .upload-file__input.disabled {
                opacity: 0.5;
            }
        }

        select {
            appearance: none;
        }

        input::-webkit-datetime-edit {
            height: 23px;
        }

        input[type="date" i]::-webkit-calendar-picker-indicator {
            opacity: 0.75;
        }

        .input-message {
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: $spacer/4;
            text-align: left;
            @include t-xs();
        
            &--error {
                color: $red;
            }
        }

        &.success {
            label {
                color: $green;
            }

            input {
                background-color: $light-green;
                color: green;
                border-bottom: 1px solid $green;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }

        &.error {
            input {
                border-bottom: 1px solid $red;
            }
        }
    }
}

@media screen and (min-width: $screen-md) {
    .form--column--2 > fieldset,
    .accordion form > fieldset {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: $spacer*2;

        > fieldset.wide {
            grid-column: 1 / 3;
        }

        > .field-group__title {
            grid-column: 1 / 3;
        }

        &.fieldset--triple {
            grid-template-columns: repeat(6, 1fr);

            > fieldset {
                &:first-child {
                    grid-column: 1 / 2;
                }
                &:nth-child(2) {
                    grid-column: 2 / 4;
                }
                &:last-child {
                    grid-column: 4 / 7;
                }
            }
        }
    }
}

// search form
.search-form {
    display: inline-flex;
    
    fieldset {
        background-color: $light;
        border-radius: $spacer;
        display: inline-flex;
        align-items: center;
        padding-left: $spacer;
        padding-right: $spacer;

        input {
            flex-grow: 1;
            background: none;
            border: none;
            outline: none;
            color: $dark;
        }

        ::placeholder {
            color: $grey-300;
        }

        button {
            padding: 5px;
        }
    }
}


// single inline field variant
.textfield-form {
    fieldset {
        position: relative;
        background: none;

        &:hover {
            .icon-wrapper--pencil {
                display: initial;
            }
        }

        &:focus-within {
            background-color: none;

            .icon--pencil path {
                display: initial;
            }
        }
    }

    input {
        padding: 0;
    }

    .textfield__input {
        font-family: $font-family-headings;
        color: inherit;
        background: none;
        border: none;
        outline: none;
        max-width: 100%;
        resize: none;

        &:focus {
            color: $blue;
        }
    }

    .icon-wrapper--pencil {
        position: absolute;
        bottom: $spacer*1.5;
        right: $spacer/2;
        display: none;

        .icon--pencil {
            fill: $blue;
        }
    }

    &--sm {
        .textfield__input {
            @include t-s();
            font-family: inherit
        }

        .icon-wrapper--pencil {
            top: 10px;
        }
    }

    &--md {
        .textfield__input {
            @include t-md();
        }

        .icon-wrapper--pencil {
            top: 15px;
        }
    }

    &--2xl .textfield__input { @include t-2xl(); }
    &--xl .textfield__input { @include t-xl(); }

    &--block {
        margin-bottom: $spacer*2;
        width: 100%;

        .textfield__input {
            width: 100%;
            display: inline-block;
            padding: $spacer $spacer*5 $spacer 0;
        }
    }

    &--inline {
        padding-right: $spacer*2;
    }

    &.placeholder {
        .textfield__input {
            color: $grey-600;

            &:focus {
                color: $blue;
            }
        }
    }

    ::placeholder {
        color: $grey-300;
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
}


// select field form with dropdown
// overwrites default react-dropdown-select styles
.react-dropdown-select.select-form {
    border: none;
    padding: 0;
    min-height: 40px;
    // z-index: 20;

    &--hidden {
        min-height: 0;
    }

    &:focus,
    &:focus-within {
        outline: none;
        box-shadow: none;
    }

    &.select-form--search,
    &.select-form--static .react-dropdown-select-dropdown {
        min-width: 100%;
        width: max-content;
        height: auto;
        background-color: white;
        border: none;
        border-radius: $spacer;
        box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
        animation: scale-fade-in 0.2s ease;
    }

    .react-dropdown-select-item,
    .react-dropdown-select-dropdown-add-new {
        border: none;
        padding-left: $spacer*2;
        padding-right: $spacer*4;
        flex-shrink: 0;

        &:not(.wrap) {
            height: 48px;
        }

        &-selected {
            background-color: $blue;
            color: $light;
        }

        &:hover,
        &:focus,
        &-active {
            background-color: $light-blue;
            color: $dark;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $grey-200;
        }
    }

    .react-dropdown-select-dropdown-handle {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        margin: 0;
    }

    
    .react-dropdown-select-no-data {
        color: $grey-400;
        line-height: 34px;
        padding: 0 $spacer;
    }

    .react-dropdown-select-dropdown-add-new {
        color: $dark;
        border-top: 1px solid $grey-200;
        line-height: 34px;
    }

    .react-dropdown-select-loading {
        padding: $spacer*10;
    
        &::after {
            width: 30px;
            height: 30px;
            border-color: $blue-grey transparent;
            margin: 0 auto;
            animation: spin 1s infinite linear;
        }
    }
}

.css-1p5w4xb-InputComponent {
    width: 100% !important;
}

.react-dropdown-select-input,
.react-dropdown-select-input::placeholder {
    font-family: $font-family;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.15px;
    color: $grey-400;
}

.react-dropdown-select-dropdown {
    @extend .t-s;
    line-height: 17px;
}

.react-dropdown-select-content,
.dd__values {
    @extend .t-sm;
    font-size: 14px !important;
}

.react-dropdown-select-content {
    .label-wrapper:hover {
        background-color: $light-blue;
        transition: background-color 0.15s ease-in;
    }
}

.select-form__label {
    .label-wrapper {
        cursor: pointer;
    }
}

.label-wrapper {
    display: inline-flex;
    align-items: center;
    padding: $spacer/2 !important;
    margin: auto 0;
    border-radius: $spacer/2;
}

// re-style so search field appears in dropdown pop-up
.select-form--search {
    position: absolute !important;
    display: block !important;
    overflow: hidden;

    .react-dropdown-select-dropdown {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        border: none;
        box-shadow: none;
        max-height: 350px;

        > span:not(.react-dropdown-select-item) {
            margin-left: 16px;
            margin-right: 32px;
            height: 48px;
        }
    }

    .react-dropdown-select-content {
        padding-left: 16px;
        padding-right: 32px;
        height: 48px;
    }
}

.select-form--hidden {
    .react-dropdown-select-dropdown-handle {
        display: none;
    }

    .react-dropdown-select-content {
        .react-dropdown-select-option {
            display: none;
        }
    }

    &.select-form--static {
        .react-dropdown-select-content {
            display: none;
        }
    }
}

.react-dropdown-select-item {
    .label-wrapper span  {
        display: inline-block;
        max-width: 350px;
    }

    &:not(.wrap) {
        .label-wrapper span  {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.react-dropdown-select-item,
.select-form__label,
.react-dropdown-select-content {
    color: $dark;
    display: flex;
    align-items: center;

    .img,
    .icon-wrapper {
        margin-right: $spacer*1.5;
        flex-shrink: 0;
    }
}

.select-form--disabled {
    .react-dropdown-select-item {
        background: none !important;
        pointer-events: none;
    }
}

.device {
    .select-form {
        z-index: 10;
    }
}

// open dropdown to right if field in aside panel
.split-panel--side {
    .select-form--static .react-dropdown-select-dropdown,
    .select-form--search {
        left: auto;
        transform: none;
        transform-origin: top center;
        z-index: 19;
        width: 320px !important;
        left: -100px;
        top: 50px;

        .label-wrapper .icon-wrapper + span {
            max-width: 250px;
        }
    }

    .action-list {
        .select-form--static .react-dropdown-select-dropdown,
        .select-form--search {
            left: 0px;
        }
    }

    .pop-up {
        width: 320px;
    }
}

.tab__panel--main__header,
.ctrls,
.batch-actions {
    .select-form--search {
        top: 100%;
        left: 50%;
        transform-origin: top center;
        z-index: 19;
        min-width: 400px !important;
        transform: translateX(-50%);
        animation: scale-fade-in-center 0.2s ease !important;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 16px 70px !important;
    }
}

.batch-actions {
    left: calc(50% + 40px);
    top: 10px;

    .select-form--search {
        top: 20px;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.4), 0px 0px 15px rgba(0, 0, 0, 0.2) !important;
    }
}

// InputBox
.input-box {
    position: relative;
    width: 100%;
    border-radius: $spacer/2;
    border: 1px solid $grey-300;
    overflow: hidden;
    background-color: $light;

    &__label {
        position: absolute;
        top: $spacer;
        left: $spacer*2;
        font-size: 13px;
        color: $grey-600;
    }

    &__input {
        width: 100%;
        border: none;
        padding: $spacer*4 $spacer*2 $spacer;
        font-size: 14px;
        color: $dark;
        background: none;

        &:focus {
            outline: none;
        }
    }

    &:focus-within {
        border-color: $blue;
        background-color: $light-blue;

        .input-box__label {
            color: $blue
        }
    }
}