.icon {
    height: $spacer*4;
    width: $spacer*4;
    flex-shrink: 0;

    &--3xs {
        height: 12px;
        width: 12px;
    }

    &--2xs {
        height: $spacer*2;
        width: $spacer*2;
    }

    &--xs {
        height: 20px;
        width: 20px;
    }

    &--sm {
        height: $spacer*3;
        width: $spacer*3;
    }

    &--lg {
        height: $spacer*5;
        width: $spacer*5;
        margin: -4px;
    }

    &--auto {
        height: auto;
        width: auto;
    }

    &--caret {
        transition: transform 0.2s ease-in;
        transform-origin: center center;

        &.caret--up {
            transform: rotateX(180deg);
        }

        &.caret--right {
            transform: rotate(-90deg);
        }
    }

    &--unpin {
        path + line {
            stroke: $blue-grey;
        }
    }
}

.icon-wrapper {
    position: relative;

    &--at::after {
        content: '@';
        position: absolute;
        text-align: center;
        width: 100%;
        font-family: $font-family-headings;
        font-weight: bold;
        color: $blue-grey;
    }

    &--dot{
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            right: 50%;
            transform: translate(-50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $blue-grey;
        }

        &.icon-wrapper--online::after {
            background-color: $green;
        }

        &.icon-wrapper--away::after {
            background-color: $yellow;
        }

        &.icon-wrapper--do_not_disturb::after {
            background-color: $red;
        }
    }
}

.icon--low-priority {
    transform: rotate(180deg)
}
