.split-panel--side,
.lister--attachments .lister__cell {
    .btn--naked:not(.accordion__toggle) {
        padding: $spacer/2;
        display: inline-flex;

        &:not([aria-disabled="true"]) {
            &:hover,
            &.active {
                background-color: $light-blue;

                svg {
                    fill: $blue;
                }
            }
        }
    }
}

.contact-detail {
    .split-panel--main-bottom {
        position: relative;
    }

    .split-panel--side,
    .lister--attachments .lister__cell {
        .btn--naked {
            padding: $spacer/2;
            display: inline-flex;

            &:not([aria-disabled="true"]) {
                &:hover,
                &:focus,
                &.active {
                    background-color: $light-blue;

                    svg {
                        fill: $blue;
                    }
                }
            }
        }
    }

    .lister--calls {
        audio {
            width: 200px;
        }
    }
}

.upload-file-wrapper {
    padding-right: 3vw;
    z-index: 3;
    position: absolute;
    right: 0;
    top: 25px;

    .upload-file-label {
        display: block;
        width: 130px;
        cursor: pointer;
    }
}

.upload-file-label {
    .upload-file-button {
        pointer-events: none;
    }
}

.lister--attachments {

    .textfield-form--block {
        margin-bottom: 0;
        overflow: hidden;
        margin-right: auto;

        fieldset {
            min-inline-size: auto;

            .textfield__input {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-top: 11px;

                &:focus {
                    white-space: pre-line;
                }
            }

            .icon-wrapper--pencil {
                bottom: 6px;
            }
        }
    }
}