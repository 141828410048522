@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1;}
}

@keyframes scale-fade-in {
    0% { transform: scale(0.9); opacity: 0; }
    100% { transform: scale(1); opacity: 1;}
}

@keyframes scale-fade-in-center {
    0% { transform: scale(0.9) translateX(-50%); opacity: 0; }
    100% { transform: scale(1) translateX(-50%); opacity: 1;}
}

.pop-up {
    position: absolute;    
    animation: scale-fade-in 0.2s ease;
    top: calc(100% + 10px);
    transform-origin: top;
    z-index: 21;
    width: 370px;
    height: max-content;

    &--block {
        width: auto;
    }

    &--top {
        top: auto;
        bottom: calc(100% + 10px);
        transform-origin: bottom;

        &.pop-up--block {
            top: 20px;
            bottom: auto;
        }
    }

    &--left {
        right: calc(100% + 10px);
        transform-origin: top right;

        &.pop-up--bottom {
            transform-origin: bottom right;
        }
    }

    &--right {
        left: calc(100% + 10px);
        transform-origin: top left;

        &.pop-up--bottom {
            transform-origin: bottom left;
        }
    }

    &--center {
        transform: translateX(-50%);
        animation: scale-fade-in-center 0.2s ease;

        &.pop-up--block {
            left: 50%
        }
    }

    .pop-up__container {
        overflow: hidden;
        position: relative;
        background-color: $light;
        padding: $spacer*3 $spacer*2.5;
        border-radius: 14px;
        box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);

        > .btn.close {
            position: absolute;
            top: $spacer;
            right: $spacer;
            border-radius: 50%;
            display: flex;
            z-index: 20;
            height: 20px;
            width: 20px;
    
            &:hover {
                background-color: rgba(120, 144, 156, 0.07);
            }
        }
    }

    &--block {
        .pop-up__container {
            box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.4), 0px 0px 15px rgba(0, 0, 0, 0.2)
        }
    }

    &--line {
        .pop-up__container {
            padding: 6px $spacer*2;
        }
    }

    .pop-up__header {
        margin-bottom: $spacer*2;
        font-size: 18px;
        letter-spacing: 0.15px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
    }

    .pop-up__message {
        margin-bottom: $spacer*2;
        padding-right: $spacer*2;
        line-height: 24px;
        color: $grey-800;
        max-height: 70vh;
        overflow: auto;

        p {
            margin-bottom: $spacer;
        }

        &.h-200 {
            max-height: 200px;
            overflow: auto;
        }
    }

    .pop-up__btns {
        display: flex;

        .btn {
            flex-grow: 1;
        }
    }
}

.batch-actions {
    .pop-up .pop-up__container > .btn.close:hover {
        background-color: $light;
    }

    .pop-up--block:not(.pop-up--primary) {
        min-width: 370px;
    }
}

.pop-up--primary {
    .pop-up__container {
        background-color: $blue;
        box-shadow: 0px 10px 30px rgba(26, 62, 126, 0.5), 0px 10px 20px rgba(15, 53, 119, 0.5);
    }

    .pop-up__btns {
        .btn {
            background-color: $blue-shaded;
            color: $light;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: auto;
            padding: $spacer*2 $spacer;
            text-transform: none;
            font-weight: 700;
            min-width: 85px;
            border-radius: $spacer;
            transition: background-color 0.2s ease-in;
            line-height: 14px;
            
            &:not(:last-child) {
                margin-right: $spacer*1.5;
            }

            &:hover {
                background-color: $light-blue;
                color: $blue;

                .icon {
                    fill: $blue;
                }
            }

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .icon {
            fill: $light;
            margin-bottom: $spacer;
        }
    }

    .btn.close {
        background-color: $light-blue;

        &:hover {
            background-color: $light;
        }

        .icon {
            fill: $blue;
        }
    }
}

.pop-up--date-picker {
    position: fixed !important;
    width: 320px !important;
    right: auto !important;
}

.pop-up--add-contact {
    max-width: 500px;
    min-width: 400px;

    .pop-up__header {
        display: block;
    }

    .contact-list {
        max-height: 250px;
        overflow-y: auto;
    }
}

.fade-in {
    animation: fade-in 0.1s ease-in;
}

.popover {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 100;

    &--centered {
        display: flex;
        justify-content: center;
        align-items: center;

        .pop-up {
            position: relative;
            top: initial;
            right: initial;
        }
    }

    &--no-padding {
        .pop-up__container {
            padding: 0;
        }
    }

    &--image {
        background-color: rgba(255, 255, 255, 0.6);

        .pop-up__container {
            display: flex;
        }

        img {
            max-width: 90vw;
            max-height: 90vh;
        }

        a.download {
            position: absolute;
            bottom: $spacer;
            right: $spacer;
        }
    }
}

.popover--new-msg,
.popover--insert-mention,
.popover--call-contact,
.popover--w {
    .pop-up__container {
        overflow: visible;
    }

    .pop-up {
        width: 500px;
    }
}

.popover--insert-template,
.popover--call-contact,
.popover--w {
    .pop-up__container {
        padding: 0;
    }

    .select-form--search {
        position: relative !important;
        max-width: 100%;

        .react-dropdown-select-item {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            line-height: 38px;
        }
    }
}

.popover--insert-template {
    .pop-up__container {
        width: 600px;
    }
}
