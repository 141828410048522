.fr-box {
    &.fr-basic {
        .fr-wrapper {
            border-radius: $spacer/2;

            ul {
                list-style-type: disc;
                list-style-position: inside;
            }
        }

        .fr-btn {
            z-index: unset !important;
        }

        .fr-toolbar {
            &.fr-top {
                display: flex;
                justify-content: space-between;
                border: none !important;
                background: none !important;
                border-radius: $spacer/2;
                flex-wrap: wrap-reverse;
            }

            .fr-btn-grp {
                margin: 0;
                display: inline-flex;
            
                + .fr-btn-grp {
                    margin-left: auto;
                }
            }
        }

        .fr-element {
            padding: $spacer*2;
        }
    }
}

.fr-wrapper {
    > div:first-child:not(.fr-element) {
        display: none !important;
    }

    .fr-placeholder {
        margin-top: 0 !important;
    }
}

.msg-editor {
    .fr-popup {
        top: -20px !important;
        left: 50% !important;
        transform: translate(-50%, -100%);
    }

    .fr-wrapper {
        max-height: 300px;
        overflow: scroll;
    }
}

.fr-popup {
    .fr-layer {
        margin: $spacer*2;
    }

    .fr-input-line {
        padding: $spacer/2 0;

        input.fr-not-empty + label {
            top: 0;
        }

        input[type="text"] {
            border-color: $grey-400;
            border-radius: $spacer/2;

            &:focus {
                border: 1px solid $blue;
            }
        }
    }

    .fr-checkbox-line {
        margin-top: 0;
    }

    .fr-checkbox {
        padding: $spacer $spacer/2 $spacer 0;

        input:checked + span {
            background: $blue;
            border: solid 1px $blue;
            border-radius: $spacer/2;
        }

        input:not(:checked) + span {
            border: solid 1px $grey-400;
            border-radius: $spacer/2;
        }
    }

    .fr-buttons:not(.fr-tabs) {
        .fr-command.fr-btn {
            margin-left: 2px;
            margin-right: 2px;
            width: 30px;
        }
    }

    .fr-action-buttons {
        height: auto;
        margin-top: $spacer;

        button.fr-command {
            border-radius: $spacer/2;
            color: $light;
            background-color: $blue;
            padding: $spacer;
            height: auto;
            box-shadow: 0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24);

            &:hover {
                color: $light;
                background-color: $blue;
                box-shadow: 0px 16px 24px rgba(54, 123, 245, 0.16), 0px 6px 12px rgba(54, 123, 245, 0.16)
            }
        }
    }
}

.fr-command.fr-btn + .fr-dropdown-menu {
    max-width: 90%;

    .fr-dropdown-wrapper {
        max-width: 100%;

        .fr-dropdown-content ul.fr-dropdown-list li a {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.fr-toolbar {
    font-family: $font-family;

    .fr-command.fr-btn {
        height: 30px;
        color: $blue-grey;

        &[data-cmd="saveChanges"]:not(.fr-disabled),
        &[data-cmd="saveComment"]:not(.fr-disabled),
        &[data-cmd="saveNote"]:not(.fr-disabled) {
            color: $blue
        }

        svg.fr-svg {
            height: 20px;
            margin: $spacer/2;

            path {
                fill: $blue-grey;
            }
        }

        &.fr-active,
        &.fr-btn-active-popup,
        &.fr-dropdown.fr-active {
            background-color: $grey-200;

            svg.fr-svg path {
                fill: $dark !important;
            }
        }
    }

    .fr-inline {
        left:150px !important;
    }
    
    .fr-newline {
        display: none;
    }

    .fr-popup {
        .fr-command.fr-btn {
            &.fr-tabs-unicode {
                font-size: 18px;
            }

            svg.fr-svg {
                margin: 0 auto;
            }
        }

        .fr-emoticon-container {
            .fr-emoticon {
                border-radius: $spacer/2;
                width: 20px;
                height: 20px;
            }
        }
    }
}

.fr-second-toolbar {
    display: none;
}

.fr-dropdown:after {
    border-top-color: $blue-grey !important;
}

.fr-dropdown[data-cmd="insertTemplate"]:after {
    display: none;
}

.editor--full {
    border-radius: $spacer/2;
    border: 1px solid $grey-300;
    padding: 0;

    .fr-wrapper {
        border: none !important;
        background: none !important;
    }

    .fr-box {
        border-radius: $spacer/2;
        background-color: $light;
    }

    &:focus-within {
        border-color: $blue;

        .fr-box {
            background-color: $light-blue;
        }
    }
}

.editor--inline {
    .fr-box {
        &.fr-basic {
            display: flex;
            flex-direction: row-reverse;

            .fr-toolbar {
                position: unset;

                .fr-command.fr-btn {
                    margin: 2px 1px;
                }

                &.fr-top {    
                    .fr-btn-grp {
                        margin: auto $spacer*4 0 2px;
                    }
                }

                &.fr-bottom {
                    border: none;
                    background: none;
                }
            }
        
            .fr-wrapper {
                flex-grow: 1;
                border-radius: $spacer*2;
                border: 1px solid $grey-300;
                background-color: $light;

                &:focus-within {
                    border-color: $blue;
                    background-color: $light-blue;
                }
            }

            .fr-element {
                min-height: auto;
                padding: 6px $spacer*1.5;
            }
        }
    }

    &.comment-editor {
        .fr-box.fr-basic {
            display: initial;
        }
    }
}

.fr-file:not(:empty)::before {
    content: '\1F4CE \00a0 ';
}

.fr-view .fr-file::after {
    content: '';
}

.fr-tooltip {
    white-space: nowrap;
}

.template,
.ticket__editor,
.task__editor {
    .fr-box.fr-basic.fr-top .fr-wrapper {
        border: none;

        .fr-element {
            min-height: 400px;
        }
    }

    .fr-box.fr-basic .fr-toolbar.fr-top {
        position: sticky;
        top: 0px;
        z-index: 2;
        background: $light !important;
    }
}

.template__editor,
.ticket__editor,
.task__editor {
    width: 100%;
    position: relative;
    border-radius: $spacer/2;
    flex-grow: 1;
    overflow: auto;

    &.bg-lightblue {
        border-radius: $spacer/2;

        .fr-element,
        .fr-box.fr-basic .fr-toolbar.fr-top {
            background: $light-blue !important;
        }
    }
}
