.messenger {
    .split-panel--main {
        position: relative;
        overflow: visible;
        display: flex;
        flex-direction: column;

        .new-mgs__container {
            flex-shrink: 0;
        }

        &.chat--single .chat__body {
            flex-grow: 1;
            overflow: auto;
        }
    }

    .split-panel--side-inner {
        z-index: 2;
    }

    .btn--primary.pos-fixed {
        top: 10px;
        right: 3vw;
    }
}