.nav {
    color: $blue-grey;
    margin: $spacer*2 0;
}

.nav__listitem {
    position: relative;

    a {
        border-radius: $spacer;
        @include t-link();
        padding: $spacer $spacer;
        text-decoration: none;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        color: $dark;
        margin-bottom: $spacer;

        &:hover {
            background-color: $grey-200;
        }

        .icon__wrapper {
            margin-right: $spacer*2;
            flex-shrink: 0;
            display: inline-flex;
        }
    }
    

    &.active {

        a {
            background-color: $dark;
            color: $light;
            
            .icon {
                fill: $light;
            }
        }
    }
}

.nav-listitem__label {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}