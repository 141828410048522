.btn,
a.btn {
    padding: 0 $spacer*1.25;
    border-radius: $spacer/2;
    cursor: pointer;
    position: relative;
    border: none;
    font-size: 14px;
    letter-spacing: 0.75px;
    font-family: $font-family;
    line-height: 24px;
    font-weight: 500;
    color: $light;
    transition: background-color 0.15s ease-in;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    &:focus {
        outline: none;
        // TODO define focused styles
    }
}

.btn--primary {
    height: 40px;
    min-width: 130px;
    padding: $spacer $spacer*1.5;
    background-color: $blue;
    box-shadow: 0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24);

    &:hover {
        box-shadow: 0px 16px 24px rgba(54, 123, 245, 0.16), 0px 6px 12px rgba(54, 123, 245, 0.16)
    }

    &:active {
        background-color: $light-blue;
        color: $blue;
    }

    .icon {
        fill: $light;
        margin-right: $spacer;
    }
}

.btn--secondary {
    background-color: rgba(0,0,0,0.1);
    color: $dark;

    &:hover {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04)
    }
}

.btn--primary,
.btn--secondary {
    &:disabled {
        pointer-events: none;
        background-color: $grey-250;
        box-shadow: none;
        color: $light;
    }
}

.btn--naked {
    background: none;
    padding: 0;
    color: inherit !important;
    text-transform: none !important;
    font-weight: inherit;
    letter-spacing: inherit;
    font-size: inherit;

    &:disabled {
        pointer-events: none;
        opacity: 0.7;
    }
}

.btn--tertiary {
    color: $blue;
    background-color: $grey-250;

    &:hover {
        background-color: $light-blue;
    }

    &:disabled {
        pointer-events: none;
        color: $grey-300;
    }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.btn--round {
    display: inline-flex;
    padding: $spacer/4;
    border-radius: 50%;
    background-color: transparent;
    margin: -$spacer/4;

    &:hover {
        background-color: $light-blue;
    }

    &[aria-checked='true'] {
        animation: pulse 0.2s ease-out;
    }

    &:disabled {
        pointer-events: none;
    }
}

a.btn--rounded,
.btn--rounded {
    @include t-s();
    font-family: $font-family-headings;
    border-radius: 20px;
    padding: 0 $spacer*2
}

.btn--icon,
a.btn--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;

    &:hover {
        background-color: rgba(120, 144, 156, 0.07);
    }

    &.active {
        background-color: $dark;
        
        svg {
            fill: $light
        }
    }

    &:disabled {
        pointer-events: none;

        &:not(.active) {
            svg {
                opacity: 0.5;
            }
        }
    }
}

.btn--inline-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    flex-shrink: 0;

    &.close {
        padding: 4px;
    }

    &:hover {
        &:not(.btn--primary) {
            background-color: rgba(120, 144, 156, 0.07);
        }
    }
}

.btn--new-item {
    color: $grey-600;
    text-transform: none;
    font-weight: normal;
    background: none;
    flex-shrink: 0;
    text-align: left;
    padding: $spacer/2 $spacer*2;
    border-radius: $spacer/2;

    &:hover {
        background-color: $light-blue;
    }

    .plus {
        color: $blue;
        display: inline-block;
        margin-right: $spacer;
    }
}

@keyframes spin { 
    from { 
        transform: rotate(0deg);
    } to { 
        transform: rotate(360deg);
    }
}

.btn__loader {
    display: inline-block;
    font-size: 35px;
    animation: spin 1s infinite linear;
    line-height: 23px;
    width: 28px;
    height: 28px;
}

.btn--success {
    background-color: $green;
    height: 40px;
    min-width: 130px;
    padding: $spacer $spacer*1.5 !important;

    &:disabled {
        pointer-events: none;
        box-shadow: none;
        opacity: 0.5;
    }
}

.MuiIconButton-root {
    padding: $spacer !important;
}

.btn--date-picker-toggle {
    &:not(.nohover):hover {
        .time-label {
            background-color: $light-blue;
        }
    }
}


.btn:hover {
    &.hover--bg {
        background-color: $grey-200;
    }

    &.hover--scale {
        transform: scale(1.2);
    }
}
