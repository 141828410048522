.crumbs-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
        line-height: 14px;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.crumbs-listitem {
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    overflow: hidden;

    a {
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            color: $dark;
        }
    }
}
