.card {
    box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);
    border-radius: $spacer;
    background-color: $light;
    overflow: hidden;
    padding: $spacer*3 $spacer*3 $spacer*4;

    @media screen and (min-width: $screen-sm) {
        padding: $spacer*4 $spacer*5 $spacer*5.5;
    }

    &--notification {
        flex-direction: column;
        padding: $spacer*2
    }
}

.logo--center {
    text-align: center;
    margin-bottom: $spacer*5;

    img {
        height: 60px;
    }
}

.contentbox--lg {
    width: 650px;
    max-width: 90vw;
    margin: $spacer*3 auto;
    text-align: center;

    @media screen and (min-width: $screen-xs) {
        margin: $spacer*6 auto;
        
        .card {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        > p {
            width: 80%;
        }

        form {
            width: 60%;
        }
    }
}

.contentbox--sm {
    width: 400px;
    max-width: 90vw;
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;
}
  