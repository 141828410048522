.popover--search {
    .pop-up {
        max-width: 80vw;
        width: 1000px;
        height: 90%;
    }

    .pop-up__container {
        height: 100%;
        padding: 0;
    }

    .pop-up__content {
        overflow: auto;
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        top: 40px;
    }
}

.header .search-form {
    &--in-popup {
        z-index: 101;
        border-bottom: 1px solid $grey-400;

        fieldset {
            max-width:80vw;
            width: 1000px;
        }
    }
}

.pop-up__search-navbar {
    background-color: $light;
    padding-left: $spacer;
    z-index: 1;
    height: 40px;
    position: relative;
    top: 40px;

    + .pop-up__content {
        height: calc(100% - 80px);
    }

    .btn {
        text-transform: uppercase;
        color: $grey-400;
        padding: $spacer $spacer*2;
        background-color:transparent !important;
        font-size: 15px;
        letter-spacing: 1px;
        font-weight: 500;

        &:hover {
            color: $grey-800;
        }

        &.active {
            color: $grey-800;
            text-decoration: underline;
        }

        &.disabled {
            display: none;
        }
    }
}

.search-results__title {
    font-size: 15px;
    color: $grey-800;
    font-weight: 500;
    letter-spacing: 1px;
    padding: $spacer*2 $spacer*3 $spacer;
}

.search-results__item {
    line-height: 22px;

    a {
        padding: $spacer/2 $spacer*3;
        align-items: center;

        &:hover {
            background-color: $grey-100;
        }
    }
}

.search-results__item-icon {
    margin-top: 3px;
}

.search-results__item-content {
    max-width: calc(100% - 50px);

    > * {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.search-results__list {
    .search-results__item {
        margin-top: $spacer/2;
    }

    .search-results__sublist {
        margin-bottom: $spacer;

        > .search-results__item a {
            padding: $spacer/4 $spacer*3 $spacer/4 $spacer*5;
        }
    }
}

