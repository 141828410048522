
.accordion {
    &.open {
        > .accordion__header--bordered {
            margin-bottom: $spacer*2;

            > * {
                padding-bottom: $spacer;
            }

            .accordion__header__label {
                border-bottom: 1px solid $grey-400;
            }
        }

        &.bg-lightgrey--open {
            background-color: $grey-100;
        }
    }

    &.closed {
        &.inactive {
            opacity: 0.5;
        }
    }

    &.bg-lightblue {
        fieldset fieldset {
            input, textarea, select, .fieldset__search-select, .upload-file__input {
                &:focus, &:active, &:focus-within {
                    background-color: $light;
                }
            }
        }
    }

    &.bg-lightgrey,
    &.bg-lightgrey--open.open {
        fieldset fieldset {
            input, textarea, select, .fieldset__search-select, .upload-file__input {
                background-color: $light;

                &:focus, &:active, &:focus-within {
                    background-color: $light-blue;
                }

                &:-webkit-autofill {
                    box-shadow: 0 0 0px 1000px $light inset;
    
                    &:focus, &:active, &:focus-within {
                        box-shadow: 0 0 0px 1000px $light-blue inset;
                    }
                }
            }
        }
    }
}

.accordion__header {
    display: flex;
    align-items: center;
    overflow: hidden;

    &.toggle--hidden {
        .accordion__toggle {
            opacity: 0 !important;
            pointer-events: none;
        }
    }

    &--icon {
        position: relative;

        .accordion__toggle {
            opacity: 0 !important;
        }

        .icon-wrapper:not(.icon-wrapper--caret) {
            display: inline-flex !important;
            position: absolute;
            left: 20px;
        }

        &:hover {
            .accordion__toggle {
                opacity: 1 !important;
                z-index: 1;
            }

            .icon-wrapper:not(.icon-wrapper--caret) {
                opacity: 0;
            }
        }
    }

    &--bordered {
        .accordion__header__label {
            margin-left: $spacer;
            flex-grow: 1;
        }
    }
}

.accordion__header__label {
    flex-grow: 1;
    max-width: 100%;

    .no-wrap {
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(100% - 28px);
        text-overflow: ellipsis;
        display: inline-block;
    }

    .owner-contract__label {
        .icon-wrapper {
            top: 2px;
        }
    }
}

.accordion__toggle {
    display: inline-flex;
}
