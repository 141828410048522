.inbox {
    padding-bottom: 100px;
}

.inbox__listitem {
    text-transform: lowercase;
    @include t-sm-wide();
    font-size: 16px;
    color: $dark;
    overflow: hidden;

    &:not(.mb-0) .accordion__header.level--0 + .accordion__body {
        margin-bottom: $spacer*3;
    }

    .accordion__toggle {
        opacity: 0;

        &:hover {
            transform: scale(1.2);
        }
    }

    .accordion__header:hover {
        .accordion__toggle {
            opacity: 1;
        }
    }

    &:not(.accordion) {
        padding-left: 20px;

        a {
            color: $dark
        }
    }

    &--empty {
        color: $grey-600;
    }

    &--active {
        &:not(.accordion) {
            color: $light;
            background-color: $blue;

            a {
                color: $light;
            }

            .icon {
                fill: $light;
            }

            .icon-wrapper--at::after {
                color: $light;
            }

            .highlighted {
                color: $light;
            }

            .textfield__input {
                color: $grey-300
            }
        }
        > .accordion__header {
            color: $light;
            background-color: $blue;

            .icon {
                fill: $light;
            }

            .icon-wrapper--at::after {
                color: $light;
            }

            .highlighted {
                color: $light;
            }

            .textfield__input {
                color: $grey-300
            }
        }
    }

    &--new {
        padding-bottom: $spacer/4;
        padding-top: $spacer/4;

        > div {
            display: inline-flex;
        }

        .textfield__input {
            width: 150px;
        }

        .btn.clear {
            opacity: 0;
        }

        &:hover .btn.clear {
            opacity: 1;
        }

        .icon-wrapper::after {
            color: $blue;
        }

        .icon-wrapper--pencil {
            top: 0;
            right: -16px;
        }
    }

    &:not(.inbox__listitem--nohover) {
        > .accordion__header:hover,
        &:not(.accordion):hover {
            background-color: $grey-200;
        }
    }

    a {
        text-decoration: none;
        width: 100%;
    }

    .level--0 {
        padding-left: 20px;

        .accordion__toggle {
            opacity: 1;
            margin-right: $spacer;
        }

        &.toggle--hidden {
            .accordion__toggle {
                opacity: 1 !important;
            }
        }

        .inbox_listitem__label .icon-wrapper {
            display: none;
        }
    }

    .level--1 {
        padding-left: $spacer*3;
    }
    
    .level--2 {
        padding-left: $spacer*6;
    }
    
    .level--3 {
        padding-left: $spacer*9;
    }
    
    .level--4 {
        padding-left: $spacer*12;
    }

    .level--5 {
        padding-left: $spacer*15;
    }

    .level--6 {
        padding-left: $spacer*18;
    }

    .level--7 {
        padding-left: $spacer*21;
    }
}

.inbox_listitem__label {
    display: flex;
    align-items: center;
    padding-top: $spacer/2;
    padding-bottom: $spacer/2;
    padding-right: $spacer*2;
    cursor: pointer;
    width: 100%;
    line-height: 18px;

    .highlighted {
        color: $dark;
        font-weight: bold;
    }

    &:focus {
        color: $dark;
    }

    .icon-wrapper {
        margin-right: $spacer;
    }

    &--level-0 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
    }
}

.inbox__spacer {
    height: $spacer;
}
