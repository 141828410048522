.header {
    margin: 10px 3vw $spacer*1.5 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-form {
        fieldset {
            height: 40px;
            width: 360px;

            input {
                @include t-link();
            }
        }
    }
}
