// description list
.dl {
    dt {
        width: 30%;
        padding: 10px 0;
    }
    
    dd {
        width: 70%;
        position: relative;
        display: flex;
        align-items: center;

        > div:first-child() {
            margin: auto 0;
        }
    
        > div:nth-child(2) {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
        }
    }

    .dl__li {
        display: flex;
        margin-bottom: 3px;

        &.dl__li--multi {
            .dd__values {
                display: inline-flex;
                flex-wrap: wrap;

                .label-wrapper {
                    border: 1px solid $grey-250;
                    border-radius: $spacer*2;
                    padding-left: 6px;
                    padding-right: 2px;
                    margin-top: 3px;
                    margin-right: 3px;

                    .btn.close {
                        margin-left: 2px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .btn.add {
            height: 30px;
            margin: 3px;
            padding-right: 3px;
            padding-left: 2px;
            font-weight: normal;
            
            &:hover {
                background-color: $light-blue;
            }
        }

        dd > .label-wrapper,
        dd > a .label-wrapper {
            max-width: 100%;
            white-space: pre-line;
            text-align: left;

            &:hover {
                background-color: $light-blue;
            }

            > .profile-img__wrapper,
            > .icon-wrapper {
                margin-right: $spacer * 1.5;
            }
        }
    }
}

// action (buttons) list
.action__li {
    display: flex;
    height: 40px;
    align-items: center;
    margin-bottom: 3px;

    .btn--inline-icon {
        @extend .t-s;
        padding: $spacer;
        margin-left: -$spacer ;
        font-weight: normal;

        &:hover {
            background-color: $light-blue;
        }

        .icon {
            margin-right: 21px;
        }
    }

    &--merge {
        .select-form {
            top: auto;
            bottom: 100%;
            transform-origin: bottom center;
        }
    }
}

ul .attachment {
    a {
        width: 100%;
        overflow: hidden;
        justify-content: flex-start;

        .attachment__name {
            flex-shrink: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.scroll__wrapper {
    overflow: hidden;
}

.split-panel--side {
    .scroll__wrapper {
        margin: -5px -30px 0 -40px;
    }
}

.split-panel--side--small {
    .scroll__wrapper {
        margin: -5px -20px 0 -30px;
    }
}

.scroll__list {
    overflow: auto;
    padding: 5px 0 40px 30px;
    margin-bottom: -40px;

    li {
        padding-right: $spacer;

        &:last-child() {
            padding-right: 30px;
        }
    }
}

.action__li button:disabled,
.action__li .btn.disabled,
.dl__li button:disabled {
    pointer-events: none;

    &:hover {
        background-color: initial !important;
    }
}

.action__li button:disabled,
.action__li .btn.disabled {
    opacity: 0.5;
}

.dl__li dd .select-form[disabled] {
    border: none;
    opacity: 1;

    .react-dropdown-select-dropdown-handle {
        display: none;
    }
}
