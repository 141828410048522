.chip {
    display: inline-flex;
    padding: $spacer/2;
    background-color: $grey-200;
    height: 22px;
    border-radius: 11px;
    vertical-align: middle;
    align-items: center;
    color: $dark;

    &--user {
        padding-right: $spacer;

        .profile-img {
            margin-right: 4px;
        }
    }

    &--employees, &--4_employees {
        background-color: $light-blue;
    }

    &--guests, &--1_guests {
        background-color: $light-yellow;
    }

    &--partners, &--3_partners {
        background-color: $light-violet;
    }

    &--landlords, &--2_landlords {
        background-color: $light-green;
    }

    &--current {
        background-color: $blue;
        color: $light;
    }

    .btn--inline-icon.close {
        padding: 0;
        margin-left: $spacer/2;

        :hover {
            svg {
                fill: $dark;
                transform: scale(1.2);
            }
        }
    }
}

.status-dot {
    display: inline-block;
    width: $spacer;
    height: $spacer;
    margin: $spacer/2;
    margin-right: $spacer*1.5;
    border-radius: $spacer/2;
    background-color: $grey-800;

    &--online {
        background-color: $green;
    }

    &--away {
        background-color: $yellow;
    }

    &--block {
        background-color: $red;
    }
}