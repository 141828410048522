.chat__header {
    background-color: $grey-100;
    min-height: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.chat-header__left {
    flex-grow: 1;
    flex-wrap: wrap;
    display: flex;
    padding: $spacer $spacer*2;
    align-items: center;
    padding-bottom: 30px;
    margin-bottom: -22px;

    button:hover {
        color: $dark !important;
    }
}

.chat-header__right {
    flex-shrink: 0;
    display: flex;
    padding-right: $spacer*2;
    align-items: center;

    .btn--inline-icon:hover {
        background: none;

        .icon--contact-multi-add,
        .icon--attachment,
        .icon--important {
            transform: scale(1.2);
        }
    }
}

.chat__members {
    padding-left: $spacer;
    display: inline-flex;

    .profile-img__wrapper {
        width: $spacer;
        height: $spacer*3;
        overflow: visible;

        .profile-img--icon {
            display: flex;
            background-color: $light;
            width: auto;

            .icon-wrapper,
            .icon {
                width: auto;
            }
        }

        &:last-child {
            margin-right: $spacer*2;
        }
    }

    &__extra {
        color: $blue-grey;
        font-size: 14px;
        line-height: 14px;
        margin-left: $spacer*2;
        vertical-align: baseline;
        align-self: flex-end;
    }
}

.chat__body {
    padding: $spacer*3 $spacer*2;
}

.chat__day-label {
    @include t-xs();
    display: inline-block;
    padding: $spacer/2 $spacer;
    margin-bottom: $spacer;
    font-weight: 500;
    border-radius: 10px;
    background-color: $grey-200;
}

.msg {
    margin-bottom: $spacer;
    max-width: 80%;
    // overflow: hidden;
    width: fit-content;

    .msg__timestamp {
        margin: $spacer/4;
        @include t-2xs();
        text-align: right;
    }

    .msg__main {
        display: flex;
        align-items: flex-end;
        position: relative;
    }

    .msg__sender {
        margin-right: $spacer;
        display: flex;
    }

    .msg__body-wrapper {
        max-width: 100%;
    }

    .msg__icons {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 100%;

        > .icon-wrapper {
            margin: 2px;
        }
    }

    .msg__img,
    .msg__video {
        border-radius: $spacer*2;
        display: flex;
        overflow: hidden;

        &:not(:first-child()) {
            margin-top: $spacer/4;
        }

        &:not(:last-child()) {
            margin-bottom: $spacer/4;
        }

        img {
            object-fit: contain;
            width: 20vw !important;
            min-width: 200px;
            height: auto;
            align-self: flex-start;
        }
    }

    .msg__img {
        display: flex;
        cursor: pointer;
    }

    video {
        max-width: 100%;
    }

    .msg__video {
        -webkit-mask-image: -webkit-radial-gradient(white, black); // fix for safari border-radius issue (https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b)

        .fr-video {
            max-width: 100%;
            display: flex;
            overflow: hidden;
            
            iframe {
                max-width: 100%;
                max-height: 300px;
            }
        }
    }

    .msg__emoticon {
        font-size: $spacer*4;
        line-height: $spacer*4;
    }

    .msg__body {
        padding: 7px $spacer*1.5;
        border-radius: $spacer*2;
        background-color: $grey-200;
        text-align: left;
        display: inline-block;
        border-bottom-left-radius: 0;
        position: relative;
        word-break: break-word;

        .fr-emoticon {
            font-size: 16px;
        }

        p[data-f-id="pbf"] {
            display: none;
        }

        ul {
            list-style-type: disc;
            padding-left: $spacer*2;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -10px;
            height: 10px;
            width: 10px;
            background: radial-gradient(circle at top left, transparent 70%, $grey-200 70%)
        }

        .msg__ref {
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: $spacer;

            .msg-ref {
                padding: $spacer/2;

                &::before {
                    display: none;
                }
            }
        }
    }

    &--right {
        text-align: right;
        margin-left: auto;

        .msg__body-wrapper,
        .msg__main {
            margin-left: auto;
        }

        .msg__main {
            flex-direction: row-reverse;
        }

        .msg__body {
            background-color: $blue;
            margin-left: auto;
            border-bottom-left-radius: $spacer*2;
            border-bottom-right-radius: 0;

            &::before {
                // display: none;
                right: -10px;
                left: auto;
                transform: rotate(90deg);
                background: radial-gradient(circle at top left, transparent 70%, $blue 70%)
            }
        }

        .msg__img {
            margin-left: auto;
        }

        .msg__icons {
            left: auto;
            right: 100%
        }
    }

    &--employees, &--4_employees {
        .msg__body {
            background-color: $light-blue;

            &::before {
                background: radial-gradient(circle at top left, transparent 70%, $light-blue 70%)
            }
        }
    }

    &--guests, &--1_guests {
        .msg__body {
            background-color: $light-yellow;

            &::before {
                background: radial-gradient(circle at top left, transparent 70%, $light-yellow 70%)
            }
        }
    }

    &--partners, &--2_partners {
        .msg__body {
            background-color: $light-violet;

            &::before {
                background: radial-gradient(circle at top left, transparent 70%, $light-violet 70%)
            }
        }
    }

    &--landlords, &--3_landlords {
        .msg__body {
            background-color: $light-green;

            &::before {
                background: radial-gradient(circle at top left, transparent 70%, $light-green 70%)
            }
        }
    }

    &--notification {
        max-width: 100%;
        width: 100%;

        .msg__main {
            flex-direction: row-reverse;
            position: relative;
        }

        .msg__body {
            width: 100%;
            background-color: $grey-250;
            color: $dark;
            border-top-left-radius: 0;
            border-bottom-left-radius: $spacer*2;
            padding: $spacer*1.5;

            &::before {
                display: none;
            }
        }

        .msg__icons {
            flex-direction: row;
            left: auto;
            top: auto;
            right: $spacer;
            bottom: $spacer;
        }

        &.msg--highlighted,
        &.msg--important {
            .msg__icons svg {
                fill: $grey-200;
            }
        }
    }

    &--highlighted {
        .msg__body {
            background-color: $yellow;

            &::before {
                background: radial-gradient(circle at top left, transparent 70%, $yellow 70%)
            }
        }
    }

    &--important {
        .msg__body {
            background-color: $red;

            &::before {
                background: radial-gradient(circle at top left, transparent 70%, $red 70%)
            }
        }
    }

    &--right,
    &--highlighted,
    &--important {
        .msg__body {
            color: $light;

            .icon {
                fill: $light;
            }

            .msg__ref {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }

    &--archived {
        .msg__body {
            opacity: 0.5;
        }
    }
    
    &--comment {
        .msg__body::before {
            display: none;
        }
    }
}

.msg-ref {
    padding-left: $spacer*1.5;
    position: relative;

    &::before {
        content: '';
        width: $spacer/2;
        border-radius: $spacer/4;
        height: 100%;
        background-color: $grey-300;
        position: absolute;
        left: 0;
        top: 0;
    }

    img {
        width: 20vw !important;
        min-width: 200px;
        height: auto;
        cursor: pointer;
    }

    &__close {
        background-color: $grey-200;
        margin-top: 0;
    }
}

.new-mgs__container {
    background-color: $grey-100;
    padding: $spacer*2;
    position: relative;
}

.msg-editor.editor--inline {
    position: relative;

    .send-btn {
        position: absolute;
        bottom: 2px;
        right: 0px;
        padding: 5px;
        z-index: 5;

        &:not(:disabled) .icon {
            fill: $blue;
        }

        &:hover {
            background-color: $grey-200;
        }

        &:disabled {
            pointer-events: none;
            touch-action: none;
        }
    }
}

.new-mgs__footer {
    padding: $spacer;
    display: flex;
    background-color: $grey-100;
    border-top: 1px dashed $grey-300;
    border-bottom-right-radius: $spacer/2;
    border-bottom-left-radius: $spacer/2;

    &--left {
        display: flex;
    }

    &--right {
        margin-left: auto;
        display: flex;

        .btn {
            height: $spacer*4;
            padding: $spacer/2 $spacer*1.5;
            min-width: auto;
        }
    }

    .btn--inline-icon {
        margin-right: $spacer;
        background-color: $grey-250;
        padding: $spacer/2;
    
        &.active {
            background-color: $light-blue;

            .icon {
                fill: $blue;
            }

            &:hover {
                background-color: $grey-250;
            }
        }

        &:hover {
            background-color: $light-blue;
        }

        &:disabled {
            pointer-events: none;
        }
    }
}

.read-receipts {
    min-width: 400px;
    max-height: 90vh;
    overflow: auto;

    li {
        display: flex;
        align-items: center;
    }
}

.on-hover--expand {
    max-width: 0px;
    overflow: hidden;
    padding: 0;
    transition: padding 0.05s ease, max-width 0.3s ease;
}

.child-on-hover:hover {
    .on-hover--expand {
        max-width: 500px;
        padding: 0 10px;
    }
}