.tab-nav {
    display: flex;
    background-color: $grey-100;
    height: $tab-nav-height;
}

.tab-nav__list {
    display: flex;
    border-bottom: 1px solid $grey-300;
}

.tab-nav__list--static {
    flex-shrink: 0;
}

.tab-nav--scroll {
    flex-grow: 1;
    overflow-x: auto;
    padding-left: 1px;
    margin-left: -1px;

    // &:not(.empty) {
    //     border-top-left-radius: 5px;
    //     border-left: 1px solid rgba(0, 0, 0, 0.15);
    // }
}

.tab-nav__list--scroll {
    min-width: 100%;
    height: 100%;
}

.tab {
    border-radius: 5px 5px 0px 0px;
    border: none;
    color: $blue-grey;
    font-family: $font-family-headings;
    margin-left: -1px;
    margin-bottom: -1px;

    &.active {
        border-top: 1px solid $grey-300;
        border-left: 1px solid $grey-300;
        border-right: 1px solid $grey-300;
        background-color: $light;
        border-bottom: none;
        color: $dark;
        z-index: 2;

        .tab__label {
            color: $dark;
        }
    }

    &:not(.active) {
        border-bottom: 1px solid $grey-300;
        border-top: 1px solid $grey-250;
        border-left: 1px solid $grey-250;
        border-right: 1px solid $grey-250;
    }

    &:not(.active):hover {
        background-color: rgba(255, 255, 255, 0.75);
    }

    a {
        display: flex;
        width: 100%;
        height: 35px;
        text-decoration: none;
        padding: $spacer;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon {
        transition: opacity 0.1s ease-in, transform 0.2s ease-in;
    }

    .icon-wrapper--pin {
        position: absolute;
        left: 0;
        top: 0;
    }

    .icon--pin {
        opacity: 0;
    }

    button:last-child() {
        padding: 2px;
        margin-left: auto;
    }

    button:first-child():hover {
        .tab__icon--type {
            opacity: 0;
        }
        .icon--pin {
            opacity: 1;
        }
    }

    button:hover {
        .icon--pin,
        .icon--unpin,
        .icon--close {
            transform: scale(1.15)
        }
    }
}

.tab--static {
    min-width: 100px;
    flex-shrink: 0;

    .tab__label {
        margin-right: 6px;
    }

    &:not(.active) {
        background-color: $grey-200;
    }
}

.tab--pinned,
.tab--dynamic {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 170px;
    min-width: 80px;
    max-width: 500px;

    a {
        padding-left: $spacer*1.5;
    }
}

.tab--pinned {
    .tab__icon--type {
        fill: $dark;
    }

    &:not(.active) {
        background-color: $grey-200;
    }
}

.tab__label {
    @extend .t-sm;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $grey-800;
    margin-left: $spacer;
}

.tab__panel {
    background-color: $light;
    height: $tab-panel-height;
    position: relative;

    &--notifications {
        height: calc(100vh - 62px);

        .tab__panel--main {
            padding: 10px 10% 50px 70px;
        }
    }
}

.tab__panel--main {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}

.split-panel {
    display: flex;
    height: 100%;
}

.split-panel--main {
    flex-grow: 1;
    height: 100%;
    padding: 50px 10% 50px 70px;
    overflow-y: scroll;
    background-color: $light;
    &:not(.p-0) {
        @extend .pl-r;
    }

    &.template--new {
        padding-top: 0;

        > form {
            padding-top: 50px;
        }
    }
}

.split-panel--main-dual {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;

    + .split-panel--right {
        z-index: 7;
    }
}

.split-panel--main-top {
    padding: 30px 70px 0px 70px;
    background-color: $light;
    @extend .pl-r;
}

.split-panel--main-nav {
    padding: 10px 70px 0px 70px;
    background-color: $light;
    border-bottom: 1px solid #C6CACC;
    position: sticky;
    top: 0;
    z-index: 6;
    @extend .pl-r;

    .tab-nav {
        background-color: $light;
    }

    .tab-nav__list {
        border-bottom: none;
    }

    .tab.active,
    .tab:hover {
        background-color: $grey-100;
        z-index: 1;
    }

    .tab:not(.active):not(:hover) {
        border-top-color: $light;
        border-left-color: $light;
        border-right-color: $light;
    }
}

.split-panel--main-bottom {
    padding: 30px 70px 50px 70px;
    background-color: $grey-100;
    flex-grow: 1;
    @extend .pl-r;

    &.lister {
        padding: 0!important;
        position: relative;

        .tab__panel--main {
            padding-top: 30px;
            padding-bottom: 50px;
        }

        thead > .spacer-row th {
            background: none;
        }
    }

    .lister--threads,
    .thread__list {
        min-height: auto;
        overflow: unset; 
    }

    .thread__listitem {
        background-color: $light;
        margin: 0 0 $spacer;
        border-radius: $spacer/2;

        &:first-child {
            margin-top: 0;
        }

        .thread__title {
            font-size: 15px;
            color: $grey-800;
        }

        &--notification {
            .thread__header {
                border-radius: $spacer/2;
                background-color: $grey-250;
            }

            &.open .thread__header {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        // &--unread {
        //     background-color: $light-blue;
        // }

        &--archived {
            opacity: 0.7;
        }
    }
}

.split-panel--side {
    flex-shrink: 0;
    height: 100%;
    overflow-y: scroll;
    width: $vertical-pannel-width;
    max-width: calc((100vw - 80px) / 3);

    section {
        padding: 20px 30px 18px 40px;
    }

    hr {
        border: none;
        border-bottom: 1px dashed $blue-grey;
        opacity: 0.32;
    }

    .react-dropdown-select {
        .react-dropdown-select-item {
            padding-right: 16px;

            &:not(.wrap) .label-wrapper {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .label-wrapper {
            width: 100%;
        }
    }
}

.split-panel--side-inner {
    position: relative;
    min-height: 100%;
    padding-bottom: 20px;
    overflow-x: visible;
}

.split-panel--right {
    padding-left: 10px;
    margin-left: -10px;
    width: $vertical-pannel-width;
    min-width: 350px;

    .split-panel--side-inner {
        box-shadow: -2px 2px 2px rgba(22, 54, 70, 0.2);
    }
}

.split-panel--side--small {
    width: $vertical-pannel-width-small;
    min-width: $vertical-pannel-width-small;

    section {
        padding-left: 30px;
        padding-right: 20px;
    }
}

.split-panel--left {
    padding-right: 10px;
    margin-right: -10px;

    .split-panel--side-inner {
        box-shadow: 2px 2px 2px rgba(22, 54, 70, 0.2);
    }
}
