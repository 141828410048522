@mixin t-2xs() {
    font-size: 9px;
    line-height: 10px;
}

@mixin t-xs() {
    font-size: 12px;
    line-height: 16px;
}

@mixin t-sm() {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.125px;
}

@mixin t-sm-wide() {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.44px;
}

@mixin t-s() {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
}

@mixin t-md() {
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.15px;
    font-weight: 500;
}

@mixin t-lg() {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.2px;
    font-weight: 500;
}

@mixin t-xl() {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0.25px;
    font-weight: 500;
}

@mixin t-2xl() {
    font-size: 48px;
    line-height: 50px;
    font-weight: 500;
}

@mixin t-link() {
    font-family: $font-family-headings;
    font-weight: 500;
    @include t-sm-wide()
}