.btn--switch {
    display: inline-flex;
    align-items: center;

    &.switched {
        .switch__label--right {
            color: $dark
        }
    }

    &:not(.switched) {
        .switch__label--left {
            color: $dark
        }
    }

    &--small {
        transform: scale(0.7);
    }
}

.switch__label--outer {
    margin: 0 $spacer;
    color: $grey-600;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    &__checkbox {
        display: none;
    }

    &__label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: $spacer*1.5;
        margin: 0;
        display: flex;
        align-items: center;
        min-height: 24px;
    }

    &__inner {
        display: block;
    }

    &__switch {
        display: block;
        width: $spacer*2;
        height: $spacer*2;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: $blue-grey;
        border-radius: $spacer;
        transition: all 0.15s ease-in 0s;
    }

    &--lbl-inner {
        width: 74px;

        .switch__inner {
            width: 200%;
            margin-left: -100%;
            transition: margin 0.15s ease-in 0s;

            &:before,
            &:after {
                display: block;
                float: left;
                width: 50%;
                height: $spacer*3;
                padding: 0;
                line-height: $spacer*3;
                font-size: 14px;
                color: $light;
                box-sizing: border-box;
                background-color: $blue-grey;
            }
        
            &:before {
                content: attr(data-left);
                padding-left: 6px;
            }

            &:after {
                content: attr(data-right);
                padding-right: 6px;
                text-align: right;
            }
        }

        .switch__switch {
            background: $light;
            margin: $spacer/2;
            border: 0 solid #bbb;
            right: 49px;
        }
    }

    &--lbl-outer {
        width: 34px;
    
        .switch__inner {
            width: 100%;
            height: $spacer;
            background-color: rgba(0,0,0,0.09);
            border-radius: $spacer/2;
            box-shadow: inset 0px 0px 0.5px 0.5px rgba(0, 0, 0, 0.1);
        }

        .switch__switch {
            right: 18px;
            margin: auto 0;
            box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.25);
        }
    }

    &--disabled {
        background-color: #ddd;
        cursor: not-allowed;

        &:before {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }

    &__checkbox:checked + &__label {
        .switch__inner {
            margin-left: 0;
        }

        .switch__switch {
            right: 0px;
        }
    }
}